import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// mui components
import { Box, Stack } from "@mui/material";

// custom components
import CustomLayout from "../../components/layout";
import LoanProgress from "../../components/loan-progress";
import CreateLoanApplication from "../../components/loan-form/create-loan-application";
import LoanAddressApplication from "../../components/loan-form/loan-address-application";
import LoanEmploymentApplication from "../../components/loan-form/loan-employment-application";
import LoanAssetsApplication from "../../components/loan-form/loan-assets-application";
import LoanDemographicApplication from "../../components/loan-form/loan-demographic-application";
import EditLoanApplication from "../../components/loan-form/edit-loan-application";
import CreditReissueApplication from "../../components/loan-form/credit-reissue-application";
import LoanTermsApplication from "../../components/loan-form/create-loan-terms";
import UnderwritingSummary from "../../components/loan-form/underwriting-summary";
import LoanPricingApplication from "../../components/loan-form/loan-pricing-application";
import LoanFeeApplication from "../../components/loan-form/create-loan-fee";
import LoanDocuments from "../../components/loan-form/create-loan-documents";
import Heading5 from "../../components/typography/heading-05";

// api slices
import { useGetLoanSummaryQuery } from "../../features/loanSummary/loanSummaryApiSlice";
import { useFieldMutation } from "../../features/field-values/fieldValueApiSlice";

// reducer slices
import {
	selectOrganizationName,
	selectUserDetails,
} from "../../features/auth/authSlice";
import { selectImpersonatedUser } from "../../features/admin/impersonated-user/impersonatedUserSlice";

// mui icons
import { CloseOutlined } from "@mui/icons-material";

// custom modals
import ConfirmationModal from "../../components/modal/confirmation-modal";
import NavigationModal from "../../components/modal/navigation-modal";

//helper utils
import { LOAN_CHANNEL_CONSTANTS } from "../../utils/common-constants";
import { selectedUserWithLoanChannel } from "../../utils/common-helpers";

// custom styles
import styles from "./index.module.scss";

export const EditLoan = () => {
	const params = useParams();
	const navigate = useNavigate();

	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);
	const organizationNameValue = useSelector(selectOrganizationName);
	const [field, { isLoading: fetchingValues }] = useFieldMutation();

	const [organizationName, setOrganizationName] = useState("");

	useEffect(() => {
		if (organizationNameValue !== undefined && organizationNameValue !== "") {
			setOrganizationName(organizationNameValue);
		}
	}, [organizationNameValue]);

	const loanSteps = [
		"Borrower Information",
		"Address",
		"Credit Reissue",
		"Employment and Income",
		"Assets and Liabilities",
		"Declarations and Others",
		"Loan Product and Terms",
		"Underwriting Summary",
		"Loan Pricing",
		`${
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR ||
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.WHS
				? "Closing Information"
				: "Fees"
		}`,
		"Supporting Documents",
	];

	const [confirmModalText, setConfirmModalText] = useState("");
	const [confirmModalVisible, setConfirmModalVisible] = useState(false);

	const handleOpenConfirmModal = () => {
		setConfirmModalVisible(true);
	};

	const handleCloseConfirmModal = () => {
		setConfirmModalVisible(false);
	};

	const handleConfirmExit = () => {
		navigate(`/home`);
	};

	const handleCloseAdminModal = () => {
		navigate(-1);
	};

	const handleSubmitConfirmModal = () => {
		navigate("/admin");
	};

	const [navigationModalText, setNavigationModalText] = useState("");
	const [navigationModalVisible, setNavigationModalVisible] = useState(false);
	const [orgLoanChannel, setOrgLoanChannel] = useState("");

	const [hideLoan, setHideLoan] = useState(false);

	const handleOpenNavigationModal = () => {
		setNavigationModalVisible(true);
	};

	const handleCloseNavigationModal = () => {
		setNavigationModalVisible(false);
	};

	const handleNavigatePipeline = () => {
		handleCloseNavigationModal();
		navigate("/pipeline");
	};

	const [loanApplicationStage, setLoanApplicationStage] = useState(1);
	const [applicationData, setApplicationData] = useState({});
	const [loanGuid, setLoanGuid] = useState("");

	useEffect(() => {
		// check if admin signed in as another user has an organization
		console.log("150 user roles:", userDetails?.user_roles);
		console.log("150 organization name:", organizationName);

		if (
			userDetails?.user_roles?.includes("admin") &&
			(organizationName === undefined ||
				organizationName === null ||
				organizationName === "")
		) {
			console.log("140 No organization");
			setConfirmModalText("Sign in as a broker to gain access.");
			handleOpenConfirmModal();
		} else {
			console.log("140 organization found:", organizationName);
			handleCloseConfirmModal();
		}
	}, [organizationName, userDetails]);

	useEffect(() => {
		// check if user is from the same organization and loan channel
		console.log("145 user channel:", userDetails?.organization_name);
		console.log("145 org loan channel:", orgLoanChannel);

		if (
			userDetails?.organization_name !== undefined &&
			userDetails?.organization_name !== "" &&
			orgLoanChannel !== undefined &&
			orgLoanChannel !== ""
		) {
			if (userDetails?.organization_name !== orgLoanChannel) {
				console.log("145 organization does not match");
				setNavigationModalText("You do not have access to this loan.");
				handleOpenNavigationModal();
				setHideLoan(true);
			}
		}
	}, [userDetails, orgLoanChannel]);

	const findFieldValue = (fieldValues, fieldId) => {
		return fieldValues?.find((o) => o.fieldId === fieldId)?.value;
	};

	const updateField = (fieldValues) => {
		console.log("180 field values:", fieldValues);
		console.log("180 user details:", userDetails);

		setOrgLoanChannel(findFieldValue(fieldValues, "TPO.X14"));

		const isExecutiveOrManager =
			userDetails?.user_roles?.includes("executive") ||
			userDetails?.user_roles?.includes("manager") ||
			userDetails?.user_roles?.includes("AE") ||
			userDetails?.user_roles?.includes("AM");

		console.log("190 is executive or manager:", isExecutiveOrManager);

		if (isExecutiveOrManager) {
			setOrganizationName(findFieldValue(fieldValues, "315"));
		}
	};

	const getFieldValues = async (loanId) => {
		console.log("135 loan id:", loanId);
		try {
			const getValues = await field({
				loan_guid: loanId,
				fields: "TPO.X14,315",
			}).unwrap();
			console.log("175 all fields:", getValues?.response);
			if (getValues?.response?.length > 0) {
				updateField(getValues?.response);
			}
		} catch (err) {
			console.log("180 error:", err);
		}
	};

	useEffect(() => {
		console.log("215 loan Guid:", loanGuid);
		console.log("215 user details:", userDetails);

		if (
			loanGuid !== undefined &&
			loanGuid !== "" &&
			userDetails !== undefined &&
			userDetails !== null
		) {
			getFieldValues(loanGuid);
		}
	}, [loanGuid, userDetails]);

	const { data: editLoanData } = useGetLoanSummaryQuery(params?.id);
	console.log("32 edit loan data:", editLoanData);
	console.log("32 edit loan number:", editLoanData?.data?.loanNumber);

	useEffect(() => {
		if (editLoanData?.data !== undefined) {
			console.log("230 edit loan data:", editLoanData?.data);
			setApplicationData(editLoanData?.data);
		}
	}, [editLoanData]);

	useEffect(() => {
		setLoanGuid(params?.id);
	}, [params]);

	const breadcrumbData = [
		{
			label: "Home",
			path: "/home",
		},
		{
			label: "Loans",
			path: "/pipeline",
		},
		{
			label: params?.id,
			path: "",
		},
	];

	return (
		<Box>
			<CustomLayout breadcrumb={breadcrumbData} disabledOverflow={true}>
				<Stack direction="row" className={styles.stackContainer}>
					<Stack direction="row" className={styles.bodyContainer}>
						<Stack direction="column" className={styles.loanContainer}>
							{/* page title and close btn container */}
							<Box direction="row" className={styles.headerContainer}>
								<Stack className={styles.headerTextContainer}>
									<Heading5
										text={`Step ${
											loanApplicationStage === 0
												? loanApplicationStage + 1
												: loanApplicationStage
										}: ${
											loanSteps[
												loanApplicationStage === 0
													? loanApplicationStage
													: loanApplicationStage - 1
											]
										}`}
										fontType="semibold"
										className={styles.headerText}
									/>
								</Stack>

								<Stack
									className={styles.closeIconContainer}
									onClick={() => {
										setConfirmModalText("Are you sure you want to exit?");
										handleOpenConfirmModal();
									}}
								>
									<CloseOutlined className={styles.closeIcon} />
								</Stack>
							</Box>

							{!hideLoan &&
								(loanApplicationStage === 0 ? (
									<CreateLoanApplication
										setLoanApplicationStage={setLoanApplicationStage}
										applicationData={applicationData}
										setApplicationData={setApplicationData}
										setLoanGuid={setLoanGuid}
										organizationName={organizationName}
										// need to pass 1 instead of 0 for the component to skip next component
										currentStage={1}
									/>
								) : loanApplicationStage === 1 ? (
									<EditLoanApplication
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										setLoanGuid={setLoanGuid}
										applicationData={applicationData}
										setApplicationData={setApplicationData}
										organizationName={organizationName}
										currentStage={1}
									/>
								) : loanApplicationStage === 2 ? (
									<LoanAddressApplication
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={2}
									/>
								) : loanApplicationStage === 3 ? (
									<CreditReissueApplication
										setLoanApplicationStage={setLoanApplicationStage}
										setApplicationData={setApplicationData}
										loanGuid={loanGuid}
										currentStage={3}
									/>
								) : loanApplicationStage === 4 ? (
									<LoanEmploymentApplication
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={4}
									/>
								) : loanApplicationStage === 5 ? (
									<LoanAssetsApplication
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={5}
									/>
								) : loanApplicationStage === 6 ? (
									<LoanDemographicApplication
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={6}
									/>
								) : loanApplicationStage === 7 ? (
									<LoanTermsApplication
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={7}
									/>
								) : loanApplicationStage === 8 ? (
									<UnderwritingSummary
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={8}
									/>
								) : loanApplicationStage === 9 ? (
									<LoanPricingApplication
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={9}
									/>
								) : loanApplicationStage === 10 ? (
									<LoanFeeApplication
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={10}
									/>
								) : (
									loanApplicationStage === 11 && (
										<LoanDocuments
											setLoanApplicationStage={setLoanApplicationStage}
											loanGuid={loanGuid}
											currentStage={11}
										/>
									)
								))}
						</Stack>

						{/* application progress */}
						<LoanProgress
							loanSteps={loanSteps}
							currentStep={
								loanApplicationStage === 0
									? loanApplicationStage
									: loanApplicationStage - 1
							}
						/>
					</Stack>
				</Stack>
			</CustomLayout>

			{/* modals */}
			<ConfirmationModal
				open={confirmModalVisible}
				text={confirmModalText}
				confirmText={
					confirmModalText?.includes("exit") ? "Exit Application" : "Sign In"
				}
				handleConfirm={
					confirmModalText?.includes("exit")
						? handleConfirmExit
						: handleSubmitConfirmModal
				}
				closeText={
					confirmModalText?.includes("exit") ? "Return to Application" : "Close"
				}
				handleClose={
					confirmModalText?.includes("exit")
						? handleCloseConfirmModal
						: handleCloseAdminModal
				}
			/>

			<NavigationModal
				open={navigationModalVisible}
				text={navigationModalText}
				confirmText="Okay"
				handleConfirm={handleNavigatePipeline}
			/>
		</Box>
	);
};

export default EditLoan;
