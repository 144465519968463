import React from "react";
import { Typography } from "@mui/material";

import styles from "./index.module.scss";

const NestedText = ({ text, extraText }) => {
	return (
		<Typography className={`${styles.nestedText} ${styles.semibold}`}>
			{text}
			<Typography display="inline" className={styles.nestedText}>
				{extraText}
			</Typography>
		</Typography>
	);
};

export default NestedText;
