import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// mui components
import { Box, Stack } from "@mui/material";

// custom components
import CustomLayout from "../../components/layout";
import LoanProgress from "../../components/loan-progress";
import LoanApplicationForm from "../../components/loan-form/create-xml-loan-application";
import EditLoanApplication from "../../components/loan-form/edit-loan-application";
import LoanAddressApplication from "../../components/loan-form/loan-address-application";
import CreditReissueApplication from "../../components/loan-form/credit-reissue-application";
import LoanEmploymentApplication from "../../components/loan-form/loan-employment-application";
import LoanAssetsApplication from "../../components/loan-form/loan-assets-application";
import LoanDemographicApplication from "../../components/loan-form/loan-demographic-application";
import LoanTermsApplication from "../../components/loan-form/create-loan-terms";
import UnderwritingSummary from "../../components/loan-form/underwriting-summary";
import LoanPricingApplication from "../../components/loan-form/loan-pricing-application";
import LoanFeeApplication from "../../components/loan-form/create-loan-fee";
import LoanDocuments from "../../components/loan-form/create-loan-documents";
import SupportingDocs from "../../components/supporting-docs";
import CustomDivider from "../../components/custom-divider";
import PrimaryButton from "../../components/buttons/primary-button";
import SecondaryButton from "../../components/buttons/secondary-button";
import Heading5 from "../../components/typography/heading-05";
import Body3 from "../../components/typography/body-03";
import Body2 from "../../components/typography/body-02";

// helper utils
import { formatBytes } from "../../utils/format-bytes";
import { LOAN_CHANNEL_CONSTANTS } from "../../utils/common-constants";
import { selectedUserWithLoanChannel } from "../../utils/common-helpers";

// api slices
import { useConvertMismoMutation } from "../../features/convert-mismo/convertMismoApiSlice";
import { useUploadV3XmlMutation } from "../../features/upload-xml/uploadV3XmlApiSlice";

// reducer slices
import {
	selectOrganizationName,
	selectUserDetails,
} from "../../features/auth/authSlice";
import { selectImpersonatedUser } from "../../features/admin/impersonated-user/impersonatedUserSlice";

// custom modal
import ProcessingModal from "../../components/modal/processing-modal";
import ConfirmationModal from "../../components/modal/confirmation-modal";
import SuccessModal from "../../components/modal/success-modal";

// mui icons
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import UploadOutlined from "@mui/icons-material/UploadOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import { CloseOutlined } from "@mui/icons-material";

// custom styles
import styles from "./index.module.scss";

const fileTypes = ["XML"];

export default function NewLoan() {
	const navigate = useNavigate();

	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);

	const loanSteps = [
		"Borrower Information",
		"Address",
		"Credit Reissue",
		"Employment and Income",
		"Assets and Liabilities",
		"Declarations and Others",
		"Loan Product and Terms",
		"Underwriting Summary",
		"Loan Pricing",
		`${
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR ||
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.WHS
				? "Closing Information"
				: "Fees"
		}`,
		"Supporting Documents",
	];

	const [uploadV3Xml, { isLoading }] = useUploadV3XmlMutation();

	const [convertMismo] = useConvertMismoMutation();

	const [confirmModalText, setConfirmModalText] = useState("");
	const [confirmModalVisible, setConfirmModalVisible] = useState(false);

	const handleOpenConfirmModal = () => {
		setConfirmModalVisible(true);
	};

	const handleCloseConfirmModal = () => {
		setConfirmModalVisible(false);
	};

	const handleConfirmExit = () => {
		navigate(`/home`);
	};

	const handleCloseAdminModal = () => {
		navigate(-1);
	};

	const handleSubmitConfirmModal = () => {
		navigate("/admin");
	};

	const organizationName = useSelector(selectOrganizationName);

	useEffect(() => {
		// check if admin signed in as another user has an organization
		console.log("150 user roles:", userDetails?.user_roles);
		console.log("150 organization name:", organizationName);

		if (
			userDetails?.user_roles?.includes("admin") &&
			(organizationName === undefined ||
				organizationName === null ||
				organizationName === "")
		) {
			console.log("140 No organization");
			setConfirmModalText("Sign in as a broker to gain access.");
			handleOpenConfirmModal();
		} else {
			console.log("140 organization found:", organizationName);
			handleCloseConfirmModal();
		}
	}, [organizationName, userDetails]);

	const [file, setFile] = useState("");

	const [fileUploadModal, setFileUploadModal] = useState(false);

	const [xmlUploaded, setXmlUploaded] = useState(false);
	const [xmlSubmitted, setXmlSubmitted] = useState(false);

	const [xmlType, setXmlType] = useState("");

	const [fileUploadedModal, setFileUploadedModal] = useState(false);
	const [loanData, setLoanData] = useState({});

	const [loanGuid, setLoanGuid] = useState("");

	const [loanApplicationStage, setLoanApplicationStage] = useState(0);

	const handleOpenFileUploadModal = () => {
		setFileUploadedModal(true);
	};

	const handleCloseFileUploadModal = () => {
		setFileUploadedModal(false);
		setXmlSubmitted(true);
	};

	const showUploadFile = (xmlType) => {
		// clear previous file
		setXmlUploaded(false);
		setFile("");

		// set new type
		setXmlType(xmlType);
	};

	const handleManualLoan = () => {
		setLoanData({});
		navigate("/create-loan");
	};

	const handleNewXml = async (file) => {
		setFileUploadModal(true);
		setFile(file);
		console.log("87 file:", file);

		var formData = new FormData();
		var xmlFile = file;
		formData.append("file", xmlFile);

		// Display the key/value pairs
		for (var pair of formData.entries()) {
			console.log(pair[0] + ", " + pair[1]);
		}

		try {
			const fileUpload = await uploadV3Xml({ formData }).unwrap();
			console.log("200 v3 xml file data:", fileUpload);
			// uploaded successfully
			setXmlUploaded(true);
			setLoanData(fileUpload?.data);
			setFileUploadModal(false);
		} catch (err) {
			console.log("95 err:", err);
			setFileUploadModal(false);
			if (!err?.originalStatus) {
				// isLoading: true until timeout occurs
				// setError("No Server Response");
			} else if (err.originalStatus === 400) {
				// setError("Missing Username or Password");
			} else if (err.originalStatus === 401) {
				// setError("Unauthorized");
			} else {
				// setError("File upload failed");
			}
		}
	};

	const handleUploadCalyx = async (file) => {
		setFileUploadModal(true);
		setFile(file);
		console.log("125 file:", file);

		var formData = new FormData();
		var xmlFile = file;
		formData.append("Calyx_XML", xmlFile);

		// Display the key/value pairs
		for (var pair of formData.entries()) {
			console.log(pair[0] + ", " + pair[1]);
		}

		try {
			const fileUpload = await convertMismo({ formData }).unwrap();
			console.log("140 uploaded file data:", fileUpload);
			// uploaded successfully
			setXmlUploaded(true);
			setLoanData(fileUpload?.data);
			setFileUploadModal(false);
		} catch (err) {
			console.log("150 err:", err);
			setFileUploadModal(false);
			if (!err?.originalStatus) {
				// isLoading: true until timeout occurs
				// setError("No Server Response");
			} else if (err.originalStatus === 400) {
				// setError("Missing Username or Password");
			} else if (err.originalStatus === 401) {
				// setError("Unauthorized");
			} else {
				// setError("File upload failed");
			}
		}
	};

	const deleteFile = (event) => {
		event.stopPropagation();
		event.preventDefault();

		setXmlUploaded(false);
		setFile("");

		console.log("deelte file");
	};

	const breadcrumbData = [
		{
			label: "Home",
			path: "/home",
		},
		{
			label: "New Loan",
			path: "",
		},
	];

	return (
		<Box>
			<CustomLayout breadcrumb={breadcrumbData} disabledOverflow={true}>
				<Stack
					direction="row"
					className={styles.stackContainer}
					sx={{
						overflow: "hidden",
					}}
				>
					{/* loan status */}
					<Stack
						direction={xmlSubmitted ? "row" : "column"}
						className={
							xmlSubmitted
								? styles.bodyContainer
								: styles.bodyContainer + " " + styles.bodySpacing
						}
					>
						{!xmlSubmitted && <Heading5 text="New Loan" fontType="semibold" />}

						{!xmlSubmitted && (
							<Stack
								direction="row"
								className={styles.applicationTypeContainer}
							>
								<Stack
									direction="column"
									className={styles.applicationTypeHeader}
								>
									<Heading5 text="Application Type" fontType="semibold" />
									<Body3 text="How would you like to fill the application?" />
								</Stack>

								<Stack direction="row" className={styles.btnContainer}>
									<SecondaryButton
										startIcon={<UploadOutlined sx={{ fontSize: "20px" }} />}
										text="Upload 3.4 XML"
										extraClass={
											xmlType === "ULAD/ILAD MISMO"
												? styles.selectedApplicationTypeBtn
												: styles.applicationTypeBtn
										}
										onClick={() => {
											showUploadFile("ULAD/ILAD MISMO");
										}}
									/>

									<SecondaryButton
										startIcon={<UploadOutlined sx={{ fontSize: "20px" }} />}
										text="Upload Calyx XML"
										extraClass={
											xmlType === "CALYX MISMO"
												? styles.selectedApplicationTypeBtn
												: styles.applicationTypeBtn
										}
										onClick={() => {
											showUploadFile("CALYX MISMO");
										}}
									/>

									{/* <SecondaryButton
										startIcon={<UploadOutlined sx={{ fontSize: "20px" }} />}
										text="Upload v3 XML"
										extraClass={
											xmlType === "v3 XML"
												? styles.selectedApplicationTypeBtn
												: styles.applicationTypeBtn
										}
										onClick={() => {
											showUploadFile("v3 XML");
										}}
									/> */}

									<SecondaryButton
										startIcon={<EditOutlinedIcon sx={{ fontSize: "20px" }} />}
										text="Fill Application Manually"
										extraClass={styles.applicationTypeBtn}
										onClick={handleManualLoan}
									/>
								</Stack>
							</Stack>
						)}

						{!xmlSubmitted && xmlType !== "" && (
							<CustomDivider extraClass={styles.dividerContainer} />
						)}

						{!xmlSubmitted && xmlType !== "" && (
							<SupportingDocs
								fileTypes={fileTypes}
								text={`or drag to upload ${
									xmlType === "ULAD/ILAD MISMO"
										? "3.4 XML"
										: // : xmlType === "v3 XML"
										  // ? "v3 XML"
										  "Calyx XML"
								} file`}
								handleFileAttach={
									xmlType === "ULAD/ILAD MISMO"
										? handleNewXml
										: // : xmlType === "v3 XML"
										  // ? handleNewXml
										  handleUploadCalyx
								}
								multiple={false}
							/>
						)}

						{/* xml uploaded but not submitted */}
						{xmlUploaded && !xmlSubmitted && (
							<Stack
								direction="column"
								className={styles.uploadedFileContainer}
							>
								<Stack direction="row" className={styles.fileListContainer}>
									<Box className={styles.fileIconContainer}>
										<UploadFileIcon className={styles.fileIcon} />
									</Box>
									<Stack
										direction="column"
										className={styles.fileNameContainer}
									>
										<Body2 text={file?.name} />
										<Body3 text={formatBytes(file?.size)} />
									</Stack>

									<Box
										sx={{ cursor: "pointer" }}
										onClick={(event) => deleteFile(event)}
										className={styles.deleteIconContainer}
									>
										<CloseIcon className={styles.deleteIcon} />
									</Box>
								</Stack>

								<Stack direction="row" className={styles.footerBtnContainer}>
									<SecondaryButton
										text="Cancel"
										onClick={(event) => deleteFile(event)}
									/>
									<PrimaryButton
										text={
											xmlType === "CALYX MISMO" ? "Submit" : "Submit Request"
										}
										onClick={handleOpenFileUploadModal}
									/>
								</Stack>
							</Stack>
						)}

						{/* xml submitted */}
						{xmlSubmitted && (
							<Stack direction="column" className={styles.loanContainer}>
								{/* page title and close btn container */}
								<Box direction="row" className={styles.headerContainer}>
									<Stack className={styles.headerTextContainer}>
										<Heading5
											text={`Step ${
												loanApplicationStage === 0
													? loanApplicationStage + 1
													: loanApplicationStage
											}: ${
												loanSteps[
													loanApplicationStage === 0
														? loanApplicationStage
														: loanApplicationStage - 1
												]
											}`}
											fontType="semibold"
											className={styles.headerText}
										/>
									</Stack>

									<Stack
										className={styles.closeIconContainer}
										onClick={() => {
											setConfirmModalText("Are you sure you want to exit?");
											handleOpenConfirmModal();
										}}
									>
										<CloseOutlined className={styles.closeIcon} />
									</Stack>
								</Box>

								{loanApplicationStage === 0 ? (
									<LoanApplicationForm
										setLoanApplicationStage={setLoanApplicationStage}
										setLoanGuid={setLoanGuid}
										xmlType={xmlType}
										applicationData={loanData}
										setApplicationData={setLoanData}
										organizationName={organizationName}
										// need to pass 1 instead of 0 for the component to skip next component
										currentStage={1}
									/>
								) : loanApplicationStage === 1 ? (
									<EditLoanApplication
										setLoanApplicationStage={setLoanApplicationStage}
										applicationData={loanData}
										setApplicationData={setLoanData}
										loanGuid={loanGuid}
										setLoanGuid={setLoanGuid}
										organizationName={organizationName}
										currentStage={1}
									/>
								) : loanApplicationStage === 2 ? (
									<LoanAddressApplication
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={2}
									/>
								) : loanApplicationStage === 3 ? (
									<CreditReissueApplication
										setLoanApplicationStage={setLoanApplicationStage}
										setApplicationData={setLoanData}
										loanGuid={loanGuid}
										currentStage={3}
									/>
								) : loanApplicationStage === 4 ? (
									<LoanEmploymentApplication
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={4}
									/>
								) : loanApplicationStage === 5 ? (
									<LoanAssetsApplication
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={5}
									/>
								) : loanApplicationStage === 6 ? (
									<LoanDemographicApplication
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={6}
									/>
								) : loanApplicationStage === 7 ? (
									<LoanTermsApplication
										data={loanData}
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={7}
									/>
								) : loanApplicationStage === 8 ? (
									<UnderwritingSummary
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={8}
									/>
								) : loanApplicationStage === 9 ? (
									<LoanPricingApplication
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={9}
									/>
								) : loanApplicationStage === 10 ? (
									<LoanFeeApplication
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={10}
									/>
								) : (
									loanApplicationStage === 11 && (
										<LoanDocuments
											setLoanApplicationStage={setLoanApplicationStage}
											loanGuid={loanGuid}
											currentStage={11}
										/>
									)
								)}
							</Stack>
						)}

						{/* application progress */}
						{xmlSubmitted && (
							<LoanProgress
								loanSteps={loanSteps}
								currentStep={
									loanApplicationStage === 0
										? loanApplicationStage
										: loanApplicationStage - 1
								}
							/>
						)}
					</Stack>
				</Stack>
			</CustomLayout>

			{/* modal */}
			<ProcessingModal
				open={fileUploadModal}
				text="Your file is being uploaded and processed"
			/>
			<SuccessModal
				open={fileUploadedModal}
				handleClose={handleCloseFileUploadModal}
				text={`Your ${
					xmlType === "CALYX MISMO" ? "CALYX POINT XML" : "3.4"
				} file has been accepted. Review and submit the
				application.`}
				confirmText={"Review"}
			/>
			<ConfirmationModal
				open={confirmModalVisible}
				text={confirmModalText}
				confirmText={
					confirmModalText?.includes("exit") ? "Exit Application" : "Sign In"
				}
				handleConfirm={
					confirmModalText?.includes("exit")
						? handleConfirmExit
						: handleSubmitConfirmModal
				}
				closeText={
					confirmModalText?.includes("exit") ? "Return to Application" : "Close"
				}
				handleClose={
					confirmModalText?.includes("exit")
						? handleCloseConfirmModal
						: handleCloseAdminModal
				}
			/>
		</Box>
	);
}
