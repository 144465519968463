import React from "react";

// mui components
import { Box, List, ListItem, ListItemText, Modal, Stack } from "@mui/material";
import { HelpOutlineOutlined } from "@mui/icons-material";

// custom components
import PrimaryButton from "../../buttons/primary-button";
import NestedText from "../../typography/nested-text";
import Heading4 from "../../typography/heading-04";
import Body2 from "../../typography/body-02";

// custom styles
import styles from "./index.module.scss";

export default function CreditScoreHelpTextModal({ open, handleClose }) {
	return (
		<Modal
			open={open}
			aria-labelledby="loading modal"
			aria-describedby="loading modal"
			disableAutoFocus={true}
			disableEscapeKeyDown={true}
		>
			<Stack direction="column" className={styles.modalBodyContainer}>
				<Stack
					direction="row"
					justifyContent="center"
					className={styles.modalHeadingContainer}
				>
					<HelpOutlineOutlined className={styles.modalHeadingIcon} />
					<Heading4
						text="Help Text"
						fontType="semibold"
						extraClass={styles.modalHeadingText}
					/>
				</Stack>
				<Stack direction="column" gap={1} className={styles.modalText}>
					<Stack
						flexDirection="row"
						sx={{ textDecoration: "underline", textUnderlineOffset: "2px" }}
					>
						<Body2
							extraClass={styles.helpCentreTitle}
							text="Qualifying Credit Score"
							fontType="semibold"
						/>
						<Body2
							extraClass={styles.helpCentreTitleSymbol}
							text="*"
							fontType="semibold"
						/>
						<Body2
							extraClass={styles.helpCentreTitle}
							text=":"
							fontType="semibold"
						/>
					</Stack>

					<List
						sx={{ listStyleType: "disc", pl: 4, verticalAlign: "middle" }}
						className={styles.mainList}
					>
						<ListItem disablePadding sx={{ display: "list-item" }}>
							<ListItemText
								disableTypography={true}
								primary={
									<Body2
										extraClass={styles.helpCentreText}
										text="Full Doc, Bank Statement, Profit & Loss, and Asset Qualification Loans:"
										fontType="semibold"
									/>
								}
							/>

							<List
								sx={{ listStyleType: "circle", pl: 4, verticalAlign: "middle" }}
								className={styles.subList}
							>
								<ListItem disablePadding sx={{ display: "list-item" }}>
									<ListItemText
										disableTypography={true}
										primary={
											<NestedText
												text="Highest Income Earner: "
												extraText="Use the Qualifying credit score of the highest income earner."
											/>
										}
									/>
								</ListItem>

								<ListItem disablePadding sx={{ display: "list-item" }}>
									<ListItemText
										disableTypography={true}
										primary={
											<NestedText
												text="Same Income: "
												extraText="If two borrowers have the same income use the borrower with the lower qualifying score."
											/>
										}
									/>
								</ListItem>

								<ListItem disablePadding sx={{ display: "list-item" }}>
									<ListItemText
										disableTypography={true}
										primary={
											<Stack>
												<NestedText
													text="Non-Occupied Co-Borrower: "
													extraText="If a Non-Occupied Co-Borrower is being used on the loan. The qualifying credit score is the lower of"
												/>

												<List
													sx={{
														pl: 4,
														verticalAlign: "middle",
													}}
													className={styles.dashedList}
												>
													<ListItem
														disablePadding
														sx={{ display: "list-item" }}
													>
														<ListItemText
															disableTypography={true}
															sx={{ display: "inline" }}
															primary={
																<Box sx={{ display: "inline" }}>
																	<Body2
																		extraClass={styles.dashedText}
																		text="Highest Income Earner occupying the property "
																	/>
																	<Body2
																		extraClass={styles.highlightedText}
																		text="OR"
																	/>
																</Box>
															}
														/>
													</ListItem>
													<ListItem
														disablePadding
														sx={{ display: "list-item" }}
													>
														<ListItemText
															disableTypography={true}
															sx={{ display: "inline" }}
															primary={
																<Body2
																	extraClass={styles.dashedText}
																	text="the non-occupying co-borrower."
																/>
															}
														/>
													</ListItem>
												</List>
											</Stack>
										}
									/>
								</ListItem>
							</List>
						</ListItem>
						<ListItem disablePadding sx={{ display: "list-item" }}>
							<ListItemText
								disableTypography={true}
								primary={
									<Body2
										extraClass={styles.helpCentreText}
										text="Debt Service Coverage Ratio Loans:"
										fontType="semibold"
									/>
								}
							/>

							<List
								sx={{ listStyleType: "circle", pl: 4, verticalAlign: "middle" }}
								className={styles.subList}
							>
								<ListItem disablePadding sx={{ display: "list-item" }}>
									<ListItemText
										disableTypography={true}
										primary={
											<NestedText extraText="Use the greatest qualifying credit score from all borrowers/grantors on the loan." />
										}
									/>
								</ListItem>
							</List>
						</ListItem>

						<ListItem disablePadding sx={{ display: "list-item" }}>
							<ListItemText
								disableTypography={true}
								primary={
									<NestedText
										text="Foreign National Borrower "
										extraText="without a credit score use 500"
									/>
								}
							/>
						</ListItem>

						<ListItem disablePadding sx={{ display: "list-item" }}>
							<ListItemText
								disableTypography={true}
								primary={
									<NestedText
										text="Jumbo and Agency Loans: "
										extraText="Use the lowest qualifying credits score of all borrowers."
									/>
								}
							/>
						</ListItem>
					</List>

					<Stack flexDirection="row">
						<Body2 extraClass={styles.highlightedText} text="*" />
						<Body2
							extraClass={styles.helpCentreFooterText}
							text="Refer to Logan Finance Corporation Non-QM Purchasing and Underwriting guidelines for information on how to calculate a borrower’s qualifying credit score."
						/>
					</Stack>
				</Stack>
				<PrimaryButton
					text="Return to portal"
					extraClass={styles.backButton}
					onClick={handleClose}
				/>
			</Stack>
		</Modal>
	);
}
