import React from "react";

// mui components
import { Checkbox, TableCell, TableRow } from "@mui/material";

// custom components
import Body3 from "../../typography/body-03";

// helper utils
import { formatCurrency } from "../../../utils/format-currency";

// custom styles
import styles from "../index.module.scss";

const EligibleProductRow = ({
	row,
	handleRowClick,
	selectedRow,
	toggleDrawer,
	selectedProduct,
	hidePriceColumn = false,
	hidePointsPercentageColumn = false,
	hidePointsValueColumn = false,
}) => {
	const pointsPercentage =
		row?.price > 100 ? row?.rebatePercent : row?.discountPercent;
	const pointsAmount =
		row?.price > 100 ? row?.rebateDollar : row?.discountDollar;

	// Compare key-value pairs in selected row with data
	const isSelected = Object.entries(selectedRow).every(([key, value]) => {
		// Check if the key exists in row and the values match
		return row.hasOwnProperty(key) && row[key] === value;
	});

	const isItemSelected =
		Object?.keys(selectedRow)?.length > 0 ? isSelected : false;
	const labelId = `enhanced-table-checkbox-${row.productId}`;

	const handleShowMoreClick = (e, rowData) => {
		console.log("40 show more:", e, rowData);

		toggleDrawer(e, "eligible-product", rowData);
	};

	return (
		<TableRow
			tabIndex={-1}
			key={row.productId}
			aria-checked={isItemSelected}
			selected={isItemSelected}
		>
			<TableCell padding="checkbox">
				<Checkbox
					color="primary"
					checked={isItemSelected}
					onChange={(event) => handleRowClick(event, row)}
					inputProps={{
						"aria-labelledby": labelId,
					}}
				/>
			</TableCell>

			<TableCell>
				<Body3
					text={`${row?.rate?.toFixed(3)}%`}
					extraClass={styles.greenText}
				/>
			</TableCell>

			{!hidePriceColumn ? (
				<TableCell>
					<Body3
						text={Number(row?.price)?.toFixed(3)}
						extraClass={styles.greenText}
					/>
				</TableCell>
			) : null}

			{!hidePointsPercentageColumn ? (
				<TableCell>
					<Body3
						text={`${pointsPercentage?.toFixed(3)}%`}
						extraClass={styles.greenText}
					/>
				</TableCell>
			) : null}

			{!hidePointsValueColumn ? (
				<TableCell>
					<Body3
						text={formatCurrency.format(Number(pointsAmount) || 0)}
						extraClass={styles.greenText}
					/>
				</TableCell>
			) : null}

			<TableCell>
				<Body3
					text={formatCurrency.format(Number(row?.totalPayment) || 0)}
					extraClass={styles.greenText}
				/>
			</TableCell>

			<TableCell>
				<Body3
					text={row?.lockPeriod || 0 + " days"}
					extraClass={styles.greenText}
				/>
			</TableCell>

			<TableCell>
				<Body3
					text={`${selectedProduct?.loanType || ""} ${
						selectedProduct?.loanTerm || ""
					} ${selectedProduct?.amortizationType || ""}`}
					extraClass={styles.greenText}
				/>
			</TableCell>

			<TableCell>
				<Body3
					text="View Details"
					isLink={true}
					extraClass={styles.pipelineNumber}
					handleClick={(e) => handleShowMoreClick(e, row)}
				/>
			</TableCell>
		</TableRow>
	);
};

export default EligibleProductRow;
