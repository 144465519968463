import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";

import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import OrderAppraisalIcon from "../../assets/icons/order-appraisal";
import ScheduleTrainingIcon from "../../assets/icons/schedule-training";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";

export const drawerTopData = [
	{
		title: "Home",
		url: "home",
		path: "/home",
		icon: <HomeOutlinedIcon sx={{ fontSize: "32px" }} />,
	},
	{
		title: "Add New Loan",
		url: "new-loan",
		path: "/new-loan",
		icon: <AddCircleOutlineOutlined sx={{ fontSize: "32px" }} />,
	},
	{
		title: "Loan Pipeline",
		url: ["pipeline", "loan-summary"],
		path: "/pipeline",
		icon: <DashboardOutlinedIcon sx={{ fontSize: "32px" }} />,
	},
	{
		title: "Resources",
		url: "resource-library",
		path: "/resource-library",
		icon: <FolderOutlinedIcon sx={{ fontSize: "32px" }} />,
	},
	{
		title: "Price my Loan",
		url: "",
		path: "https://www.lfclift.com/",
		icon: <MonetizationOnOutlinedIcon sx={{ fontSize: "32px" }} />,
	},
	{
		title: "Order Appraisal",
		url: "",
		path: "https://lender.reggora.com/auth/login",
		icon: <OrderAppraisalIcon />,
	},
	{
		title: "Schedule Training",
		url: "",
		path: "https://appt.link/train-with-logan-finance/submission-portal-training",
		icon: <ScheduleTrainingIcon extraStyles={{ fill: "transparent" }} />,
	},
	{
		title: "Training Videos",
		url: "",
		path: "https://lfctpo.com/portal-training-videos/",
		icon: <PlayCircleOutlinedIcon extraStyles={{ fill: "transparent" }} />,
	},
];

export const drawerBottomData = [
	{
		title: "Admin",
		url: "admin",
		path: "/admin",
		icon: <PersonOutlinedIcon sx={{ fontSize: "32px" }} />,
	},
];
