import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// mui components
import { Box, Stack } from "@mui/system";
import { IconButton, InputLabel } from "@mui/material";
import { ErrorOutlined, HelpOutlineOutlined } from "@mui/icons-material";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import SecondarySelect from "../../custom-select/secondary-select";
import MaskedInput from "../../custom-input/masked-input";
import CustomCheckbox from "../../custom-checkbox";
import CustomDivider from "../../custom-divider";
import Heading5 from "../../typography/heading-05";
import Heading6 from "../../typography/heading-06";
import Body3 from "../../typography/body-03";

// reducer slice
import { selectUserDetails } from "../../../features/auth/authSlice";
import { selectImpersonatedUser } from "../../../features/admin/impersonated-user/impersonatedUserSlice";
import { useGetUserStateLicenseMutation } from "../../../features/user-state-license/userStateLicenseApiSlice";

// helper utils
import { stateOptions } from "../../../utils/select-options";
import { selectedUserWithLoanChannel } from "../../../utils/common-helpers";
import { LOAN_CHANNEL_CONSTANTS } from "../../../utils/common-constants";

// custom animations
import Lottie from "lottie-react";
import loadingAnimation from "../../../assets/animations/loading.json";

// custom styles
import styles from "./index.module.scss";

const LoanOriginator = ({
	organizationName,
	organizationDetails,
	loanOriginatorEmail,
	setLoanOriginatorEmail,
	organizationUsers,
	loanOriginator,
	setLoanOriginator,
	setOrganizationLicense,
	propertyState,
	manualOriginator,
	setManualOriginator,
	originatorData,
	setOriginatorData,
	disabled,
	viewLoan,
	error,
	setHelpText,
	setExtraHelpText,
	handleOpenHelpText,
}) => {
	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);

	const [organizationStateLicense, setOrganizationStateLicense] = useState("");
	const [userStateLicense, setUserStateLicense] = useState("");

	const isAdmin =
		userDetails?.user_roles?.includes("admin") === true &&
		(impersonatedUser?.email === undefined || impersonatedUser?.email === null);

	console.log("260 is admin:", isAdmin);

	const isExecutiveOrManager =
		userDetails?.user_roles?.includes("executive") ||
		userDetails?.user_roles?.includes("manager") ||
		userDetails?.user_roles?.includes("AE") ||
		userDetails?.user_roles?.includes("AM");

	console.log("270 is executive or manager:", isExecutiveOrManager);

	const [getUserStateLicense, { isLoading: isLoadingUserStateLicense }] =
		useGetUserStateLicenseMutation();

	useEffect(() => {
		if (propertyState !== "") {
			let matchedLicense = organizationDetails?.state_licenses?.find(
				(item) =>
					item.selected === true && item.stateAbbrevation === propertyState
			);

			if (
				matchedLicense !== undefined &&
				matchedLicense?.licenseNumber !== ""
			) {
				setOrganizationStateLicense(matchedLicense?.licenseNumber);
				setOrganizationLicense(matchedLicense?.licenseNumber);
			} else {
				setOrganizationStateLicense("");
				setOrganizationLicense("");
			}
		} else {
			setOrganizationStateLicense("");
			setOrganizationLicense("");
		}
	}, [organizationDetails, propertyState]);

	const getUserLicense = async (userId) => {
		let userLicense = await getUserStateLicense({
			brokerId: userId,
		}).unwrap();

		console.log("56 userLicense:", userLicense);

		let matchedLicense = userLicense?.find(
			(item) =>
				item.selected === true && item.stateAbbrevation === propertyState
		);

		console.log("60 matched license:", matchedLicense);

		if (matchedLicense !== undefined && matchedLicense?.licenseNumber !== "") {
			setUserStateLicense(matchedLicense?.licenseNumber);
		} else {
			setUserStateLicense("");
		}

		if (
			matchedLicense?.licenseNumber !== undefined &&
			matchedLicense?.licenseNumber !== ""
		) {
			setLoanOriginator((prev) => {
				return { ...prev, state_license: matchedLicense?.licenseNumber };
			});
		} else {
			setLoanOriginator((prev) => {
				return { ...prev, state_license: "" };
			});
		}
	};

	useEffect(() => {
		if (loanOriginatorEmail !== "" && propertyState !== "") {
			console.log("100 org users:", organizationUsers);

			let matchedUser = organizationUsers?.find(
				(item) => item.value === loanOriginatorEmail
			);

			console.log("matched user:", matchedUser);

			if (
				matchedUser !== null &&
				matchedUser !== undefined &&
				matchedUser?.broker_id !== ""
			) {
				getUserLicense(matchedUser?.broker_id);
			}
		}
	}, [loanOriginatorEmail, propertyState]);

	return (
		<Box className={styles.cardContainer}>
			<Stack direction="column" className={styles.cardHeaderContainer}>
				<Heading5 text="Loan Originator Information" fontType="semibold" />

				<Body3 text="Provide details about the loan originator" />
			</Stack>

			{(selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
				selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR ||
				isAdmin ||
				isExecutiveOrManager) && (
				<Stack direction="row" className={styles.cardHeaderContainer}>
					<CustomCheckbox
						checked={manualOriginator || false}
						handleChange={() => setManualOriginator(!manualOriginator)}
						label="Manual Input"
						disabled={disabled}
					/>

					<IconButton
						className={styles.helperIconBtn}
						onClick={() => {
							setHelpText(
								"Manual Input Selected: The system will use the Loan Originators Information entered manually."
							);
							setExtraHelpText(
								"Manual Input NOT Selected: The user can pick an “Loan Originator” from a dropdown menu."
							);
							handleOpenHelpText();
						}}
					>
						<HelpOutlineOutlined className={styles.helperIcon} />
					</IconButton>
				</Stack>
			)}

			{!manualOriginator ? (
				<Stack direction="column" className={styles.formSectionContainer}>
					{/* organization information */}
					<Stack direction="row" className={styles.inputSectionContainer}>
						{/* organization name */}
						<Stack
							direction="column"
							className={
								styles.inputContainer + " " + styles.fullWidthContainer
							}
						>
							<InputLabel htmlFor="outlined-input-lo-organization-name">
								<Heading6
									text="Organization Name"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-lo-organization-name"
								type="text"
								placeholder="Organization Name"
								fullWidth={true}
								value={organizationName}
								disabled={true}
							/>
						</Stack>

						{/* address line */}
						<Stack
							direction="column"
							className={
								styles.inputContainer + " " + styles.fullWidthContainer
							}
						>
							<InputLabel htmlFor="outlined-input-lo-address-line">
								<Heading6
									text="Address Line 1"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-lo-address-line"
								type="text"
								placeholder="Address Line"
								fullWidth={true}
								value={organizationDetails?.address}
								disabled={true}
							/>
						</Stack>

						{/* unit number */}
						<Stack
							direction="column"
							className={
								styles.inputContainer + " " + styles.fullWidthContainer
							}
						>
							<InputLabel htmlFor="outlined-input-lo-unit-number">
								<Heading6
									text="Address Line 2 / Unit Number"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-lo-unit-number"
								type="text"
								placeholder="Unit Number"
								fullWidth={true}
								// value={data?.addressUnitIdentifier}
								disabled={true}
							/>
						</Stack>

						{/* city */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="outlined-input-lo-city">
								<Heading6
									text="City"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-lo-city"
								type="text"
								placeholder="City"
								value={organizationDetails?.city}
								disabled={true}
							/>
						</Stack>

						{/* state */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="select-lo-state">
								<Heading6
									text="State"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id="select-lo-state"
								displayEmpty={true}
								value={organizationDetails?.state || ""}
								options={stateOptions}
								disabled={true}
							/>
						</Stack>

						{/* postal code */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="outlined-input-lo-postal-code">
								<Heading6
									text="Postal Code"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-lo-postal-code"
								type="text"
								placeholder="Zip Code"
								value={organizationDetails?.zip}
								disabled={true}
							/>
						</Stack>
					</Stack>

					{/* organization information */}
					<Stack direction="row" className={styles.inputSectionContainer}>
						{/* organization NMLS */}
						<Stack
							direction="column"
							className={styles.inputContainer + " " + styles.inlineContainer}
						>
							<InputLabel htmlFor="outlined-input-lo-organization-nmls">
								<Heading6
									text="LO Organization NMLS"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-lo-organization-nmls"
								type="text"
								placeholder="LO Organization NMLS"
								fullWidth={true}
								value={organizationDetails?.nmls_id}
								disabled={true}
							/>
						</Stack>

						{/* state license */}
						<Stack
							direction="column"
							className={styles.inputContainer + " " + styles.inlineContainer}
						>
							<InputLabel htmlFor="outlined-input-lo-org-license">
								<Heading6
									text="State License Number"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-lo-org-license"
								type="text"
								placeholder="State License Number"
								fullWidth={true}
								value={organizationStateLicense}
								disabled={true}
							/>
						</Stack>
					</Stack>

					{/* loan originator information */}
					<Stack direction="row" className={styles.inputSectionContainer}>
						{/* LO email */}
						<Stack
							direction="column"
							className={
								styles.inputContainer + " " + styles.fullWidthContainer
							}
						>
							<InputLabel htmlFor="select-lo-email">
								<Heading6
									text="Loan Originator"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id="select-lo-email"
								displayEmpty={true}
								fullWidth={true}
								value={loanOriginatorEmail || ""}
								handleChange={(event) =>
									setLoanOriginatorEmail(event.target.value)
								}
								options={organizationUsers}
								disabled={disabled}
								error={
									error &&
									(loanOriginatorEmail === undefined ||
										loanOriginatorEmail === "")
								}
							/>
						</Stack>
					</Stack>

					{isLoadingUserStateLicense ? (
						<Box className={styles.animationContainer}>
							<Lottie animationData={loadingAnimation} loop={true} />
						</Box>
					) : (
						<Stack direction="row" className={styles.inputSectionContainer}>
							{/* LO Name */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="outlined-input-lo-name">
									<Heading6
										text="Loan Officer Name"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="outlined-input-lo-name"
									type="text"
									placeholder="LO Name"
									value={
										viewLoan
											? loanOriginator?.user?.name
											: (loanOriginator?.user?.first_name || "") +
											  " " +
											  (loanOriginator?.user?.last_name || "")
									}
									disabled={true}
								/>
							</Stack>

							{/* LO NMLS ID */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="outlined-input-lo-nmls">
									<Heading6
										text="NMLS ID"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="outlined-input-lo-nmls"
									type="text"
									placeholder="NMLS ID"
									value={loanOriginator?.nmls_id}
									disabled={true}
								/>
							</Stack>

							{/* LO state license */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="outlined-input-lo-license">
									<Heading6
										text="State License Number"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="outlined-input-lo-license"
									type="text"
									placeholder="State License Number"
									value={
										viewLoan ? loanOriginator?.stateLicense : userStateLicense
									}
									disabled={true}
								/>
							</Stack>

							{/* LO phone */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="outlined-input-lo-phone">
									<Heading6
										text="Phone Number"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="outlined-input-lo-phone"
									type="text"
									placeholder="[XXX] XXX-XXXX"
									value={loanOriginator?.phone}
									disabled={true}
								/>
							</Stack>

							{/* LO cell phone */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="outlined-input-lo-cell-phone">
									<Heading6
										text="Cell Phone Number"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="outlined-input-lo-cell-phone"
									type="text"
									placeholder="[XXX] XXX-XXXX"
									value={
										loanOriginator?.cell_phone === null ||
										loanOriginator?.cell_phone === undefined
											? ""
											: loanOriginator?.cell_phone
									}
									disabled={true}
								/>
							</Stack>

							{/* LO email */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel htmlFor="outlined-input-lo-email">
									<Heading6
										text="Email ID"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id="outlined-input-lo-email"
									type="email"
									placeholder="email@mail.com"
									value={loanOriginator?.user?.email}
									disabled={true}
								/>
							</Stack>
						</Stack>
					)}
				</Stack>
			) : (
				<Stack direction="column" className={styles.formSectionContainer}>
					{/* organization information */}
					<Stack direction="row" className={styles.inputSectionContainer}>
						{/* organization name */}
						<Stack
							direction="column"
							className={
								styles.inputContainer + " " + styles.fullWidthContainer
							}
						>
							<InputLabel htmlFor="outlined-input-lo-organization-name">
								<Heading6
									text="Organization Name"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-lo-organization-name"
								type="text"
								placeholder="Organization Name"
								fullWidth={true}
								value={originatorData?.orgName}
								handleChange={(event) =>
									setOriginatorData((prevState) => ({
										...prevState,
										orgName: event.target.value,
									}))
								}
								disabled={disabled}
								error={
									error &&
									(originatorData?.orgName === undefined ||
										originatorData?.orgName === "")
								}
								showEndIcon={
									error &&
									(originatorData?.orgName === undefined ||
										originatorData?.orgName === "")
								}
								endIcon={<ErrorOutlined className={styles.errorIcon} />}
							/>
						</Stack>

						{/* address line */}
						<Stack
							direction="column"
							className={
								styles.inputContainer + " " + styles.fullWidthContainer
							}
						>
							<InputLabel htmlFor="outlined-input-lo-address-line">
								<Heading6
									text="Address Line 1"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-lo-address-line"
								type="text"
								placeholder="Address Line"
								fullWidth={true}
								value={originatorData?.address}
								handleChange={(event) =>
									setOriginatorData((prevState) => ({
										...prevState,
										address: event.target.value,
									}))
								}
								disabled={disabled}
								error={
									error &&
									(originatorData?.address === undefined ||
										originatorData?.address === "")
								}
								showEndIcon={
									error &&
									(originatorData?.address === undefined ||
										originatorData?.address === "")
								}
								endIcon={<ErrorOutlined className={styles.errorIcon} />}
							/>
						</Stack>

						{/* unit number */}
						<Stack
							direction="column"
							className={
								styles.inputContainer + " " + styles.fullWidthContainer
							}
						>
							<InputLabel htmlFor="outlined-input-lo-unit-number">
								<Heading6
									text="Address Line 2 / Unit Number"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-lo-unit-number"
								type="text"
								placeholder="Unit Number"
								fullWidth={true}
								value={originatorData?.unitNumber}
								handleChange={(event) =>
									setOriginatorData((prevState) => ({
										...prevState,
										unitNumber: event.target.value,
									}))
								}
								disabled={disabled}
							/>
						</Stack>

						{/* city */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="outlined-input-lo-city">
								<Heading6
									text="City"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-lo-city"
								type="text"
								placeholder="City"
								value={originatorData?.city}
								handleChange={(event) =>
									setOriginatorData((prevState) => ({
										...prevState,
										city: event.target.value,
									}))
								}
								disabled={disabled}
								error={
									error &&
									(originatorData?.city === undefined ||
										originatorData?.city === "")
								}
								showEndIcon={
									error &&
									(originatorData?.city === undefined ||
										originatorData?.city === "")
								}
								endIcon={<ErrorOutlined className={styles.errorIcon} />}
							/>
						</Stack>

						{/* state */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="select-lo-state">
								<Heading6
									text="State"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id="select-lo-state"
								displayEmpty={true}
								value={originatorData?.state || ""}
								handleChange={(event) =>
									setOriginatorData((prevState) => ({
										...prevState,
										state: event.target.value,
									}))
								}
								options={stateOptions}
								disabled={disabled}
								error={
									error &&
									(originatorData?.state === undefined ||
										originatorData?.state === "")
								}
							/>
						</Stack>

						{/* postal code */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="outlined-input-lo-postal-code">
								<Heading6
									text="Postal Code"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-lo-postal-code"
								type="text"
								placeholder="Zip Code"
								value={originatorData?.zipcode}
								handleChange={(event) =>
									setOriginatorData((prevState) => ({
										...prevState,
										zipcode: event.target.value,
									}))
								}
								disabled={disabled}
								error={
									error &&
									(originatorData?.zipcode === undefined ||
										originatorData?.zipcode === "")
								}
								showEndIcon={
									error &&
									(originatorData?.zipcode === undefined ||
										originatorData?.zipcode === "")
								}
								endIcon={<ErrorOutlined className={styles.errorIcon} />}
							/>
						</Stack>
					</Stack>

					{/* organization information */}
					<Stack direction="row" className={styles.inputSectionContainer}>
						{/* organization NMLS */}
						<Stack
							direction="column"
							className={styles.inputContainer + " " + styles.inlineContainer}
						>
							<InputLabel htmlFor="outlined-input-lo-organization-nmls">
								<Heading6
									text="LO Organization NMLS"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-lo-organization-nmls"
								type="text"
								placeholder="LO Organization NMLS"
								fullWidth={true}
								value={originatorData?.orgNmls}
								handleChange={(event) =>
									setOriginatorData((prevState) => ({
										...prevState,
										orgNmls: event.target.value,
									}))
								}
								disabled={disabled}
								error={
									error &&
									(originatorData?.orgNmls === undefined ||
										originatorData?.orgNmls === "")
								}
								showEndIcon={
									error &&
									(originatorData?.orgNmls === undefined ||
										originatorData?.orgNmls === "")
								}
								endIcon={<ErrorOutlined className={styles.errorIcon} />}
							/>
						</Stack>

						{/* state license */}
						<Stack
							direction="column"
							className={styles.inputContainer + " " + styles.inlineContainer}
						>
							<InputLabel htmlFor="outlined-input-lo-org-license">
								<Heading6
									text="State License Number"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-lo-org-license"
								type="text"
								placeholder="State License Number"
								fullWidth={true}
								value={originatorData?.orgStateLicense}
								handleChange={(event) =>
									setOriginatorData((prevState) => ({
										...prevState,
										orgStateLicense: event.target.value,
									}))
								}
								disabled={disabled}
								error={
									error &&
									(originatorData?.orgStateLicense === undefined ||
										originatorData?.orgStateLicense === "")
								}
								showEndIcon={
									error &&
									(originatorData?.orgStateLicense === undefined ||
										originatorData?.orgStateLicense === "")
								}
								endIcon={<ErrorOutlined className={styles.errorIcon} />}
							/>
						</Stack>
					</Stack>

					{/* loan originator information */}
					<Stack direction="row" className={styles.inputSectionContainer}>
						{/* LO email */}
						<Stack
							direction="column"
							className={
								styles.inputContainer + " " + styles.fullWidthContainer
							}
						>
							<InputLabel htmlFor="outlined-input-lo-email">
								<Heading6
									text="Loan Originator Email"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-lo-email"
								type="email"
								placeholder="email@mail.com"
								fullWidth={true}
								value={originatorData?.originatorEmail}
								handleChange={(event) =>
									setOriginatorData((prevState) => ({
										...prevState,
										originatorEmail: event.target.value,
									}))
								}
								disabled={disabled}
								error={
									error &&
									(originatorData?.originatorEmail === undefined ||
										originatorData?.originatorEmail === "")
								}
								showEndIcon={
									error &&
									(originatorData?.originatorEmail === undefined ||
										originatorData?.originatorEmail === "")
								}
								endIcon={<ErrorOutlined className={styles.errorIcon} />}
							/>
						</Stack>
					</Stack>

					<Stack direction="row" className={styles.inputSectionContainer}>
						{/* LO First Name */}
						<Stack
							direction="column"
							className={styles.inputContainer + " " + styles.inlineContainer}
						>
							<InputLabel htmlFor="outlined-input-lo-first-name">
								<Heading6
									text="Loan Officer First Name"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-lo-first-name"
								type="text"
								placeholder="LO First Name"
								fullWidth={true}
								value={originatorData?.originatorFirstName}
								handleChange={(event) =>
									setOriginatorData((prevState) => ({
										...prevState,
										originatorFirstName: event.target.value,
									}))
								}
								disabled={disabled}
								error={
									error &&
									(originatorData?.originatorFirstName === undefined ||
										originatorData?.originatorFirstName === "")
								}
								showEndIcon={
									error &&
									(originatorData?.originatorFirstName === undefined ||
										originatorData?.originatorFirstName === "")
								}
								endIcon={<ErrorOutlined className={styles.errorIcon} />}
							/>
						</Stack>

						{/* LO Last Name */}
						<Stack
							direction="column"
							className={styles.inputContainer + " " + styles.inlineContainer}
						>
							<InputLabel htmlFor="outlined-input-lo-last-name">
								<Heading6
									text="Loan Officer Last Name"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-lo-last-name"
								type="text"
								placeholder="LO Last Name"
								fullWidth={true}
								value={originatorData?.originatorLastName}
								handleChange={(event) =>
									setOriginatorData((prevState) => ({
										...prevState,
										originatorLastName: event.target.value,
									}))
								}
								disabled={disabled}
								error={
									error &&
									(originatorData?.originatorLastName === undefined ||
										originatorData?.originatorLastName === "")
								}
								showEndIcon={
									error &&
									(originatorData?.originatorLastName === undefined ||
										originatorData?.originatorLastName === "")
								}
								endIcon={<ErrorOutlined className={styles.errorIcon} />}
							/>
						</Stack>

						{/* LO NMLS ID */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="outlined-input-lo-nmls">
								<Heading6
									text="NMLS ID"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-lo-nmls"
								type="text"
								placeholder="NMLS ID"
								value={originatorData?.originatorNmls}
								handleChange={(event) =>
									setOriginatorData((prevState) => ({
										...prevState,
										originatorNmls: event.target.value,
									}))
								}
								disabled={disabled}
								error={
									error &&
									(originatorData?.originatorNmls === undefined ||
										originatorData?.originatorNmls === "")
								}
								showEndIcon={
									error &&
									(originatorData?.originatorNmls === undefined ||
										originatorData?.originatorNmls === "")
								}
								endIcon={<ErrorOutlined className={styles.errorIcon} />}
							/>
						</Stack>

						{/* LO state license */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="outlined-input-lo-license">
								<Heading6
									text="State License Number"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-lo-license"
								type="text"
								placeholder="State License Number"
								value={originatorData?.originatorStateLicense}
								handleChange={(event) =>
									setOriginatorData((prevState) => ({
										...prevState,
										originatorStateLicense: event.target.value,
									}))
								}
								disabled={disabled}
							/>
						</Stack>

						{/* LO phone */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="outlined-input-lo-phone">
								<Heading6
									text="Phone Number"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<MaskedInput
								id="outlined-input-lo-phone"
								type="phone"
								placeholder="[XXX] XXX-XXXX"
								value={originatorData?.originatorPhone}
								onInputChange={(value) => {
									setOriginatorData((prevState) => ({
										...prevState,
										originatorPhone: value,
									}));
								}}
								disabled={disabled}
								error={
									error &&
									(originatorData?.originatorPhone === undefined ||
										originatorData?.originatorPhone === "")
								}
								showEndIcon={
									error &&
									(originatorData?.originatorPhone === undefined ||
										originatorData?.originatorPhone === "")
								}
								endIcon={<ErrorOutlined className={styles.errorIcon} />}
							/>
						</Stack>
					</Stack>
				</Stack>
			)}
			<CustomDivider />
		</Box>
	);
};

export default LoanOriginator;
