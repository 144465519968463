import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// mui components
import { Box, Stack } from "@mui/material";

// custom components
import CustomLayout from "../../components/layout";
import CustomTable from "../../components/custom-table";
import TemporaryDrawer from "../../components/temporary-drawer";
import PrimaryButton from "../../components/buttons/primary-button";
import SecondaryButton from "../../components/buttons/secondary-button";
import Heading3 from "../../components/typography/heading-03";

// api slice
import { useViewRepositoryMutation } from "../../features/view-repository/viewRepositoryApiSlice";
import { useDeleteDocumentRepositoryMutation } from "../../features/admin/delete-document-repository/deleteDocumentRepositoryApiSlice";
import { useResourceLibraryFolderMutation } from "../../features/resource-library-folder/resourceLibraryFolderApiSlice";
import { useDeleteDocumentFolderMutation } from "../../features/admin/delete-document-folder/deleteDocumentFolderApiSlice";
import { useRenameRepositoryFileMutation } from "../../features/admin/rename-repository-file/renameRepositoryFileApiSlice";

// reducer slice
import { selectUserDetails } from "../../features/auth/authSlice";

// custom modal
import LoaderModal from "../../components/modal/loader-modal";
import RenameModal from "../../components/modal/rename-modal";
import ConfirmationModal from "../../components/modal/confirmation-modal";
import ProcessingModal from "../../components/modal/processing-modal";
import SuccessModal from "../../components/modal/success-modal";
import ErrorModal from "../../components/modal/error-modal";

// mui icons
import { AddOutlined, ArrowBackOutlined } from "@mui/icons-material";

// custom styles
import styles from "./index.module.scss";

const ResourceLibrary = () => {
	const [folderList, setFolderList] = useState([]);

	const folderTableColumns = [
		{ id: "name", label: "Name", minWidth: 200 },
		{ id: "type", label: "Type", minWidth: 100 },
		{ id: "date-created", label: "Date Created", minWidth: 150 },
	];

	const adminFolderTableColumns = [
		{ id: "name", label: "Name", minWidth: 200 },
		{ id: "type", label: "Type", minWidth: 100 },
		{ id: "date-created", label: "Date Created", minWidth: 150 },
		{ id: "actions", label: "", minWidth: 125 },
	];

	const fileTableColumns = [
		{ id: "name", label: "Name", minWidth: 200 },
		{ id: "type", label: "Type", minWidth: 100 },
		{ id: "upload-date", label: "Upload Date", minWidth: 150 },
		{ id: "actions", label: "", minWidth: 125 },
	];

	const adminFileTableColumns = [
		{ id: "name", label: "Name", minWidth: 200 },
		{ id: "type", label: "Type", minWidth: 100 },
		{ id: "upload-date", label: "Upload Date", minWidth: 150 },
		{ id: "actions", label: "", minWidth: 125 },
	];

	const [selectedFolder, setSelectedFolder] = useState("");

	const [renameParams, setRenameParams] = useState("");
	const [renameModalText, setRenameModalText] = useState("");
	const [renameModalVisible, setRenameModalVisible] = useState(false);

	const handleCloseRenameModal = () => {
		setRenameModalVisible(false);
	};

	const handleOpenRenameModal = () => {
		setRenameModalVisible(true);
	};

	const [deleteParams, setDeleteParams] = useState("");
	const [deleteModalText, setDeleteModalText] = useState("");
	const [deleteRepositoryModalVisible, setDeleteRepositoryModalVisible] =
		useState(false);

	const handleCloseDeleteRepositoryModal = () => {
		setDeleteRepositoryModalVisible(false);
	};

	const handleOpenDeleteRepositoryModal = () => {
		setDeleteRepositoryModalVisible(true);
	};

	const [resourceFiles, setResourceFiles] = useState([]);
	const [filteredResourceFiles, setFilteredResourceFiles] = useState([]);

	// custom drawer
	const [drawerType, setDrawerType] = useState("");
	const [drawerOpen, setDrawerOpen] = useState({});

	const handleOpenDrawer = () => {
		setDrawerOpen({
			right: true,
		});
	};

	const handleCloseDrawer = () => {
		setDrawerOpen({
			right: false,
		});
	};

	const [processingText, setProcessingText] = useState("");
	const [processingModalVisible, setProcessingModalVisible] = useState(false);

	const handleOpenProcessingModal = () => {
		setProcessingModalVisible(true);
	};

	const handleCloseProcessingModal = () => {
		setProcessingModalVisible(false);
	};

	const [successText, setSuccessText] = useState("");
	const [successModalVisible, setSuccessModalVisible] = useState(false);

	const handleOpenSuccessModal = () => {
		setSuccessModalVisible(true);
	};

	const handleCloseSuccessModal = () => {
		setSuccessModalVisible(false);
	};

	const [errorText, setErrorText] = useState("");
	const [errorModalVisible, setErrorModalVisible] = useState(false);

	const handleOpenErrorModal = () => {
		setErrorModalVisible(true);
	};

	const handleCloseErrorModal = () => {
		setErrorModalVisible(false);
	};

	const [resourceLibraryFolder, { isLoading: requestingFolder }] =
		useResourceLibraryFolderMutation();

	const [viewRepository, { isLoading: requestingFileList }] =
		useViewRepositoryMutation();

	const [deleteDocumentRepository, { isLoading: deletingFile }] =
		useDeleteDocumentRepositoryMutation();

	const [deleteDocumentFolder, { isLoading: deletingFolder }] =
		useDeleteDocumentFolderMutation();

	const [renameRepositoryFile, { isLoading: renamingFile }] =
		useRenameRepositoryFileMutation();

	const userDetails = useSelector(selectUserDetails);

	const getResourceFolders = async () => {
		const folderList = await resourceLibraryFolder().unwrap();
		console.log("175 folder list:", folderList);
		setFolderList(folderList);
	};

	const handleDeleteFolder = async (folderName, index) => {
		console.log("185 delete folder:", folderName, index);

		try {
			const documentRepositoryData = await deleteDocumentFolder({
				folderName,
			}).unwrap();

			if (documentRepositoryData?.message?.includes("success")) {
				let newArr = [...folderList];
				newArr.splice(index, 1);

				console.log("157 new arr:", newArr);

				setFolderList([...newArr]);
				handleOpenSuccessModal();
			}
		} catch (err) {
			console.log("95 err:", err);
			setErrorText(err.data.message);
			handleOpenErrorModal();
		}
	};

	const handleDeleteFile = async (itemId, index) => {
		console.log("205 delete file:", itemId, index);

		try {
			const documentRepositoryData = await deleteDocumentRepository({
				itemId,
			}).unwrap();

			if (documentRepositoryData?.message?.includes("success")) {
				let newArr = [...resourceFiles];
				newArr.splice(index, 1);

				console.log("157 new arr:", newArr);

				setResourceFiles([...newArr]);
				setFilteredResourceFiles([...newArr]);

				handleOpenSuccessModal();
			}
		} catch (err) {
			console.log("95 err:", err);
			setErrorText(err.data.message);
			handleOpenErrorModal();
		}
	};

	const handleRenameFile = async (itemId, index) => {
		var formData = new FormData();
		formData.append("updated_file_name", renameParams?.title);

		try {
			const documentRepositoryData = await renameRepositoryFile({
				itemId,
				formData,
			}).unwrap();

			if (documentRepositoryData?.message?.includes("success")) {
				// 1. Make a shallow copy of the items
				let newArr = [...resourceFiles];

				// 2. Make a shallow copy of the item you want to mutate
				let file = { ...newArr[index] };

				// 3. Replace the property you're intested in
				file.file_name = renameParams?.title;

				// 4. Put it back into our array. N.B. we *are* mutating the array here,
				//    but that's why we made a copy first
				newArr[index] = file;

				// 5. Set the state to our new copy
				setResourceFiles([...newArr]);
				setFilteredResourceFiles([...newArr]);

				setSuccessText(
					`Successfully renamed file "${renameModalText}" to "${renameParams?.title}".`
				);
				handleOpenSuccessModal();
			}
		} catch (err) {
			console.log("95 err:", err);
			setErrorText(err.data.message);
			handleOpenErrorModal();
		}
	};

	useEffect(() => {
		getResourceFolders();
	}, []);

	const getFolderDetails = async (folderName) => {
		setSelectedFolder(folderName);
		setResourceFiles([]);
		setFilteredResourceFiles([]);

		try {
			const fileList = await viewRepository({ folderName }).unwrap();
			console.log("275 file list:", fileList?.data);

			setResourceFiles(fileList?.data);
			setFilteredResourceFiles(fileList?.data);
		} catch (err) {
			console.log("275 err:", err);
			setErrorText(err.data.message);
			handleOpenErrorModal();
		}
	};

	const handleDeleteFolderClick = (folderName, index) => {
		setDeleteParams({
			type: "folder",
			folderName,
			id: index,
		});
		setDeleteModalText(`folder "${folderName}"`);
		setSuccessText(`Successfully deleted folder ${folderName}.`);
		handleOpenDeleteRepositoryModal();
	};

	const handleRenameFileClick = (fileName, itemId, index) => {
		setRenameParams({
			itemId,
			title: "",
			id: index,
		});

		setRenameModalText(fileName);
		handleOpenRenameModal();
	};

	const handleSubmitRename = () => {
		handleCloseRenameModal();
		handleRenameFile(renameParams?.itemId, renameParams?.id);
	};

	const handleDeleteFileClick = (fileName, itemId, index) => {
		setDeleteParams({
			type: "file",
			itemId,
			id: index,
		});

		setDeleteModalText(`file "${fileName}"`);
		setSuccessText(`Successfully deleted file ${fileName}.`);
		handleOpenDeleteRepositoryModal();
	};

	const handleSubmitDelete = () => {
		handleCloseDeleteRepositoryModal();

		if (deleteParams?.type === "folder") {
			handleDeleteFolder(deleteParams?.folderName, deleteParams?.id);
		} else if (deleteParams?.type === "file") {
			handleDeleteFile(deleteParams?.itemId, deleteParams?.id);
		}
	};

	const toggleDrawer = (event, drawerType) => {
		if (drawerType !== undefined && drawerType !== "") {
			setDrawerType(drawerType);
			handleOpenDrawer();
		} else {
			setDrawerType("");
			handleCloseDrawer();
		}
	};

	useEffect(() => {
		if (!successModalVisible) {
			getResourceFolders();

			if (selectedFolder !== "") {
				getFolderDetails(selectedFolder);
			}
		}
	}, [successModalVisible]);

	const breadcrumbData = [
		{
			label: "Home",
			path: "/home",
		},
		{
			label: "Resources",
			path: "/resource-library",
		},
	];

	return (
		<Box>
			<CustomLayout breadcrumb={breadcrumbData} disabledOverflow={true}>
				<Stack
					direction="row"
					className={styles.stackContainer}
					sx={{
						overflow: "hidden",
					}}
				>
					{/* pipeline table */}
					<Stack direction="column" className={styles.tableContainer}>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							className={styles.tableTitleContainer}
						>
							<Heading3
								text={
									selectedFolder !== "" ? selectedFolder : "Manage Resources"
								}
								fontType="semibold"
							/>

							<Stack direction="row" className={styles.headerBtnContainer}>
								{selectedFolder !== "" && (
									<SecondaryButton
										text="Go Back"
										startIcon={
											<ArrowBackOutlined className={styles.backIcon} />
										}
										onClick={() => setSelectedFolder("")}
									/>
								)}
								{userDetails?.user_roles?.includes("admin") && (
									<PrimaryButton
										text="Add Resources"
										startIcon={<AddOutlined className={styles.addIcon} />}
										onClick={(e) => toggleDrawer(e, "add-resource")}
									/>
								)}
							</Stack>
						</Stack>

						{selectedFolder === "" ? (
							<CustomTable
								key={Math.floor(Math.random() * 100)}
								columnData={
									userDetails?.user_roles?.includes("admin")
										? adminFolderTableColumns
										: folderTableColumns
								}
								rowData={folderList}
								tableType="resources-folder"
								handleFolderClick={getFolderDetails}
								handleFolderDelete={handleDeleteFolderClick}
							/>
						) : (
							<CustomTable
								key={Math.floor(Math.random() * 100)}
								columnData={
									userDetails?.user_roles?.includes("admin")
										? adminFileTableColumns
										: fileTableColumns
								}
								rowData={filteredResourceFiles}
								tableType="resources-file"
								handleFileRename={handleRenameFileClick}
								handleFileDelete={handleDeleteFileClick}
							/>
						)}
					</Stack>
				</Stack>

				<TemporaryDrawer
					// drawer props
					drawerOpen={drawerOpen}
					drawerDirection="right"
					drawerType={drawerType}
					toggleDrawer={toggleDrawer}
					handleCloseDrawer={handleCloseDrawer}
					handleOpenProcessingModal={handleOpenProcessingModal}
					handleCloseProcessingModal={handleCloseProcessingModal}
					setProcessingText={setProcessingText}
					handleOpenSuccessModal={handleOpenSuccessModal}
					setSuccessText={setSuccessText}
					handleOpenErrorModal={handleOpenErrorModal}
					setErrorText={setErrorText}
				/>
			</CustomLayout>
			<RenameModal
				open={renameModalVisible}
				text={renameModalText}
				renameText={renameParams}
				setRenameText={setRenameParams}
				handleSubmit={handleSubmitRename}
				handleClose={handleCloseRenameModal}
			/>
			<ConfirmationModal
				open={deleteRepositoryModalVisible}
				text={`Are you sure you want to delete the ${deleteModalText}?`}
				confirmText="Delete"
				handleConfirm={handleSubmitDelete}
				closeText="Cancel"
				handleClose={handleCloseDeleteRepositoryModal}
			/>
			<LoaderModal
				open={
					requestingFolder ||
					requestingFileList ||
					renamingFile ||
					deletingFolder ||
					deletingFile
				}
			/>
			<ProcessingModal open={processingModalVisible} text={processingText} />
			<SuccessModal
				open={successModalVisible}
				handleClose={handleCloseSuccessModal}
				text={successText}
			/>
			<ErrorModal
				open={errorModalVisible}
				text={errorText}
				handleClose={handleCloseErrorModal}
			/>
		</Box>
	);
};

export default ResourceLibrary;
