import React, { useEffect, useState } from "react";

// mui components
import {
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
} from "@mui/material";

// custom components
import CustomLoader from "../loader";

// custom header format
import TableHeadCheckboxSort from "./table-head/table-head-checkbox-sort";
import TableHeadConditionTracking from "./table-head/table-header-condition-tracking";
import TableHeadSort from "./table-head/table-head-sort";
import CustomTableHead from "./table-head";
import TableHeadSatisfyCondition from "./table-head/table-header-satisfy-condition";
import TableHeadResourceFile from "./table-head/table-header-resource-file";
import TableHeadEligibleProduct from "./table-head/table-head-eligible-product";
import TableHeadIneligibleProduct from "./table-head/table-head-ineligible-product";
import TableHeadPipeline from "./table-head/table-head-pipeline";

// custom row format
import LoanStatusRow from "./table-row/loan-status-row";
import RateLockRow from "./table-row/rate-lock-row";
import PipelineRow from "./table-row/pipeline-row";
import LockHistoryRow from "./table-row/lock-history-row";
import ConditionTrackingRow from "./table-row/condition-tracking";
import SatisfyingConditionsRow from "./table-row/satisfying-conditions";
import ChangeRequestRow from "./table-row/change-request";
import BrokerDocumentRow from "./table-row/broker-documents";
import AdditionalIncomeRow from "./table-row/additional-income";
import AssetInformationRow from "./table-row/asset-information";
import LiabilityInformationRow from "./table-row/liability-information";
import RealEstateInformationRow from "./table-row/real-estate-information";
import ResourceFolderRow from "./table-row/resource-folder-row";
import ResourceFileRow from "./table-row/resource-file-row";
import AdminUserRow from "./table-row/admin-user";
import LinkLiabilityRow from "./table-row/link-liability";
import AdminPipelineRow from "./table-row/admin-pipeline-row";
import AppraisalDocumentsRow from "./table-row/appraisal-documents-row";
import OtherAssetsInformationRow from "./table-row/other-assets-information";
import OtherLiabilitiesInformationRow from "./table-row/other-liabilities-information";
import EligibleProductRow from "./table-row/eligible-product";
import EligibleProductAdjustmentsRow from "./table-row/eligible-product-adjustments";
import EligibleProductPaymentsRow from "./table-row/eligible-product-payments";
import IneligibleProductRow from "./table-row/ineligible-product";

// custom styles
import styles from "./index.module.scss";

const CustomTable = ({
	columnData,
	isSubmitted,
	rowData,
	tableType,
	squaredBorder,
	maxHeight,
	isLoading,
	enableSelect,
	enableSort,
	setSortedData,
	selectedRow,
	setSelectedRow,
	setSelectedProduct,
	currentProductName,
	handleRemoveRow,
	handleUpdate,
	disableRow,
	// real estate
	liabilityCount,
	handleReOwned,
	handleViewLinked,
	// resources folder
	handleFolderClick,
	handleFolderDelete,
	// resources file
	handleFileRename,
	handleFileDelete,
	// admin user
	selectedBroker,
	handleSelectBroker,
	// link liability
	selectedRealEstate,
	handleSelectLiability,
	// condition tracking
	handleConditionUploadClick,
	// admin pipeline
	handleOpenConfirmModal,
	// appraisal documents
	handleDownload,
	// eligible pricing products
	toggleDrawer,
	extraData,
	selectedProduct,
	hidePriceColumn = false,
	hidePointsPercentageColumn = false,
	hidePointsValueColumn = false,
}) => {
	const [order, setOrder] = useState("desc");
	const [orderBy, setOrderBy] = useState("number");

	const [filteredLoans, setFilteredLoans] = useState(rowData);

	const [minimizedRow, setMinimizedRow] = useState([]);

	const handleAddMinimizeRow = (rowId) => {
		setMinimizedRow((prevArray) => [...prevArray, rowId]);
	};

	const handleDeleteMinimizeRow = (rowId) => {
		setMinimizedRow((prevArray) => prevArray.filter((item) => item !== rowId));
	};

	useEffect(() => {
		if (
			tableType !== "pipeline" &&
			tableType !== "admin-pipeline" &&
			filteredLoans?.length === 0
		) {
			console.log("120 row data:", rowData);
			setFilteredLoans(rowData);
		}
	}, [rowData]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	// TODO uncomment to enable pagination
	// const [page, setPage] = useState(0);
	// const [rowsPerPage, setRowsPerPage] = useState(10);

	// const handleChangePage = (event, newPage) => {
	// 	setPage(newPage);
	// };

	// const handleChangeRowsPerPage = (event) => {
	// 	setRowsPerPage(+event.target.value);
	// 	setPage(0);
	// };

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = rowData?.map((n) => n.id);
			setSelectedRow(newSelected);
			return;
		}
		setSelectedRow([]);
	};

	const handleSelectRowClick = (event, rowId) => {
		const selectedIndex = selectedRow.indexOf(rowId);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selectedRow, rowId);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selectedRow.slice(1));
		} else if (selectedIndex === selectedRow.length - 1) {
			newSelected = newSelected.concat(selectedRow.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selectedRow.slice(0, selectedIndex),
				selectedRow.slice(selectedIndex + 1)
			);
		}

		setSelectedRow(newSelected);
	};

	const handleSelectEligibleRowClick = (event, productData) => {
		console.log("150 product data:", productData);
		console.log("150 currentProductName:", currentProductName);

		setSelectedRow(productData);
		setSelectedProduct(currentProductName);
	};

	const customClass = `${styles.tablePaperContainer} ${
		squaredBorder ? styles.squaredBorderContainer : ""
	} ${maxHeight ? styles.maxHeight : ""}`;

	return (
		<Paper sx={{ width: "100%", overflow: "hidden" }} className={customClass}>
			<TableContainer className={styles.tableContainer}>
				<Table stickyHeader aria-label="sticky table" className={styles.table}>
					<TableHead data={columnData} />

					{tableType === "condition-tracking" ? (
						<TableHeadConditionTracking
							data={columnData}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={rowData.length}
							setSortedData={setSortedData}
							filteredLoans={filteredLoans}
							setFilteredLoans={setFilteredLoans}
						/>
					) : tableType === "satisfying-conditions" ? (
						<TableHeadSatisfyCondition
							data={columnData}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={rowData.length}
							setSortedData={setSortedData}
							filteredLoans={filteredLoans}
							setFilteredLoans={setFilteredLoans}
						/>
					) : tableType === "resources-file" ? (
						<TableHeadResourceFile
							data={columnData}
							rowData={rowData}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={rowData.length}
							setSortedData={setSortedData}
							filteredLoans={filteredLoans}
							setFilteredLoans={setFilteredLoans}
						/>
					) : tableType === "eligible-product" ? (
						<TableHeadEligibleProduct
							data={columnData}
							rowData={rowData}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={rowData.length}
							setSortedData={setSortedData}
						/>
					) : tableType === "ineligible-product" ? (
						<TableHeadIneligibleProduct
							data={columnData}
							rowData={rowData}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={rowData.length}
							setSortedData={setSortedData}
						/>
					) : tableType === "pipeline" || tableType === "admin-pipeline" ? (
						<TableHeadPipeline
							data={columnData}
							rowData={rowData}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={rowData.length}
							setSortedData={setSortedData}
							filteredLoans={filteredLoans}
							setFilteredLoans={setFilteredLoans}
						/>
					) : enableSelect ? (
						<TableHeadCheckboxSort
							data={columnData}
							rowData={rowData}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={rowData.length}
							setSortedData={setSortedData}
							selectedCount={selectedRow.length}
							onSelectAllClick={handleSelectAllClick}
						/>
					) : enableSort ? (
						<TableHeadSort
							data={columnData}
							rowData={rowData}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={rowData?.length}
							setSortedData={setSortedData}
						/>
					) : (
						<CustomTableHead data={columnData} />
					)}

					{/* TODO uncomment to enable loader */}
					{/* {!isLoading ? (
						<TableBody
							sx={{
								backgroundColor: "red",
							}}
						>
							<TableRow>
								<TableCell align="center">
									<CustomLoader />
								</TableCell>
							</TableRow>
						</TableBody>
					) : ( */}

					<TableBody>
						{tableType === "admin-pipeline" && filteredLoans?.length > 0
							? filteredLoans?.map((row, index) => (
									<AdminPipelineRow
										row={row}
										key={index}
										handleOpenConfirmModal={handleOpenConfirmModal}
									/>
							  ))
							: tableType === "pipeline" && filteredLoans?.length > 0
							? filteredLoans?.map((row, index) => (
									<PipelineRow row={row} key={index} />
							  ))
							: tableType === "condition-tracking" && filteredLoans?.length > 0
							? filteredLoans?.map((row, index) => (
									<ConditionTrackingRow
										key={index}
										row={row}
										handleUploadClick={handleConditionUploadClick}
										minimizedRow={minimizedRow}
										handleAddMinimizeRow={handleAddMinimizeRow}
										handleDeleteMinimizeRow={handleDeleteMinimizeRow}
									/>
							  ))
							: tableType === "satisfying-conditions" &&
							  filteredLoans?.length > 0
							? filteredLoans?.map((row, index) => (
									<SatisfyingConditionsRow
										key={index}
										row={row}
										handleRowClick={handleSelectRowClick}
										selectedRow={selectedRow}
										minimizedRow={minimizedRow}
										handleAddMinimizeRow={handleAddMinimizeRow}
										handleDeleteMinimizeRow={handleDeleteMinimizeRow}
									/>
							  ))
							: tableType === "resources-file" && filteredLoans?.length > 0
							? filteredLoans?.map((row, index) => (
									<ResourceFileRow
										key={index}
										row={row}
										index={index}
										// resources file
										handleFileRename={handleFileRename}
										handleFileDelete={handleFileDelete}
									/>
							  ))
							: rowData
									// TODO uncomment to enable pagination
									// .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									?.map((row, index) =>
										tableType === "loan-status" ? (
											<LoanStatusRow row={row} key={index} />
										) : tableType === "rate-lock" ? (
											<RateLockRow row={row} key={index} />
										) : tableType === "lock-history" ? (
											<LockHistoryRow row={row} key={index} />
										) : tableType === "change-request" ? (
											<ChangeRequestRow row={row} key={index} />
										) : tableType === "broker-documents" ? (
											<BrokerDocumentRow
												row={row}
												key={index}
												handleRowClick={handleSelectRowClick}
												selectedRow={selectedRow}
											/>
										) : tableType === "additional-income" ? (
											<AdditionalIncomeRow
												key={index}
												row={row}
												index={index}
												handleRemoveRow={handleRemoveRow}
												handleUpdate={handleUpdate}
												disableRow={disableRow}
											/>
										) : tableType === "asset-information" ? (
											<AssetInformationRow
												key={index}
												row={row}
												index={index}
												handleRemoveRow={handleRemoveRow}
												handleUpdate={handleUpdate}
												disableRow={disableRow}
											/>
										) : tableType === "liabilities-information" ? (
											<LiabilityInformationRow
												key={index}
												row={row}
												index={index}
												handleRemoveRow={handleRemoveRow}
												handleUpdate={handleUpdate}
												disableRow={disableRow}
												isSubmitted={isSubmitted}
											/>
										) : tableType === "real-estate-information" ? (
											<RealEstateInformationRow
												key={index}
												row={row}
												index={index}
												handleRemoveRow={handleRemoveRow}
												handleUpdate={handleUpdate}
												disableRow={disableRow}
												liabilityCount={liabilityCount}
												handleReOwned={handleReOwned}
												handleViewLinked={handleViewLinked}
												isSubmitted={isSubmitted}
											/>
										) : tableType === "resources-folder" ? (
											<ResourceFolderRow
												key={index}
												row={row}
												index={index}
												// resources folder
												handleFolderClick={handleFolderClick}
												handleFolderDelete={handleFolderDelete}
											/>
										) : tableType === "admin-users" ? (
											<AdminUserRow
												key={index}
												row={row}
												index={index}
												selectedBroker={selectedBroker}
												handleSelectBroker={handleSelectBroker}
											/>
										) : tableType === "link-liability" ? (
											<LinkLiabilityRow
												key={index}
												row={row}
												index={index}
												selectedRealEstate={selectedRealEstate}
												handleSelectLiability={handleSelectLiability}
											/>
										) : tableType === "appraisal-documents" ? (
											<AppraisalDocumentsRow
												key={index}
												row={row}
												handleDownload={handleDownload}
											/>
										) : tableType === "other-assets-information" ? (
											<OtherAssetsInformationRow
												key={index}
												row={row}
												index={index}
												handleRemoveRow={handleRemoveRow}
												handleUpdate={handleUpdate}
												disableRow={disableRow}
											/>
										) : tableType === "other-liabilities-information" ? (
											<OtherLiabilitiesInformationRow
												key={index}
												row={row}
												index={index}
												handleRemoveRow={handleRemoveRow}
												handleUpdate={handleUpdate}
												disableRow={disableRow}
											/>
										) : tableType === "eligible-product" ? (
											<EligibleProductRow
												row={row}
												key={index}
												handleRowClick={handleSelectEligibleRowClick}
												selectedRow={selectedRow}
												toggleDrawer={toggleDrawer}
												selectedProduct={selectedProduct}
												hidePriceColumn={hidePriceColumn}
												hidePointsPercentageColumn={hidePointsPercentageColumn}
												hidePointsValueColumn={hidePointsValueColumn}
											/>
										) : tableType === "eligible-product-adjustments" ? (
											<EligibleProductAdjustmentsRow
												key={index}
												id={index}
												row={row}
												rowData={rowData}
												extraData={extraData}
											/>
										) : tableType === "eligible-product-payments" ? (
											<EligibleProductPaymentsRow row={row} key={index} />
										) : (
											tableType === "ineligible-product" && (
												<IneligibleProductRow
													row={row}
													key={index}
													handleRowClick={handleSelectEligibleRowClick}
													selectedRow={selectedRow}
												/>
											)
										)
									)}
					</TableBody>
					{/* )} */}
				</Table>
			</TableContainer>

			{/* TODO uncomment to enable pagination */}
			{/* <TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={rows.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/> */}
		</Paper>
	);
};

export default CustomTable;
