import React from "react";

const LoganLogo = ({ extraStyles }) => {
	return (
		<svg
			version="1.0"
			width="431px"
			height="93px"
			viewBox="0 0 431 93"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			style={extraStyles}
		>
			<g
				transform="translate(0, 93) scale(0.1, -0.1)"
				fill="#000000"
				stroke="none"
			>
				<path d="M1909 917 c-146 -42 -232 -154 -232 -304 0 -61 5 -82 32 -137 36 -73 72 -108 151 -145 46 -22 69 -26 145 -26 76 0 99 4 146 26 69 33 124 87 155 153 34 72 34 190 0 262 -50 108 -150 172 -279 180 -42 2 -91 -1 -118 -9z m177 -126 c23 -10 54 -36 70 -57 26 -34 29 -45 29 -119 0 -74 -3 -85 -29 -119 -97 -128 -301 -84 -335 73 -13 61 5 139 40 178 55 60 150 78 225 44z" />
				<path d="M2604 910 c-78 -25 -144 -83 -181 -158 -37 -75 -40 -191 -6 -263 81 -177 294 -239 491 -143 l52 25 0 125 0 124 -60 0 -60 0 0 -86 0 -85 -35 -15 c-160 -66 -321 90 -261 254 41 115 196 158 301 84 l43 -30 41 42 41 42 -27 26 c-73 70 -224 96 -339 58z" />
				<path d="M0 465 l0 -455 460 0 460 0 0 100 0 100 -355 0 -355 0 0 355 0 355 -105 0 -105 0 0 -455z" />
				<path d="M310 620 l0 -300 60 0 60 0 0 240 0 240 200 0 200 0 0 -50 0 -50 -140 0 -140 0 0 -70 0 -70 110 0 110 0 0 -50 0 -50 -110 0 -110 0 0 -70 0 -70 185 0 185 0 0 300 0 300 -305 0 -305 0 0 -300z" />
				<path d="M1210 615 l0 -305 225 0 226 0 -3 58 -3 57 -152 3 -153 3 0 244 0 245 -70 0 -70 0 0 -305z" />
				<path d="M3155 633 c-70 -159 -131 -296 -137 -305 -8 -16 -2 -18 66 -18 l74 0 25 63 25 62 140 3 140 3 25 -63 25 -63 76 -3 75 -3 -48 108 c-26 59 -87 197 -136 306 l-87 197 -68 0 -68 0 -127 -287z m237 44 c21 -50 40 -100 44 -109 6 -16 -2 -18 -85 -18 -50 0 -91 3 -91 8 0 13 83 212 89 212 3 0 23 -42 43 -93z" />
				<path d="M3750 615 l0 -305 70 0 70 0 2 182 3 183 150 -182 150 -182 58 -1 57 0 0 305 0 305 -70 0 -70 0 -2 -182 -3 -182 -150 182 -150 182 -57 0 -58 0 0 -305z" />
				<path d="M1210 104 c0 -104 0 -105 23 -102 18 2 23 10 25 41 3 37 4 37 48 37 37 0 44 3 44 20 0 17 -7 20 -45 20 -41 0 -45 2 -45 25 0 23 3 25 50 25 43 0 50 3 50 20 0 18 -7 20 -75 20 l-75 0 0 -106z" />
				<path d="M1760 105 c0 -98 1 -105 20 -105 19 0 20 7 20 105 0 98 -1 105 -20 105 -19 0 -20 -7 -20 -105z" />
				<path d="M2172 108 c3 -98 4 -103 26 -106 21 -3 22 0 22 60 l1 63 27 -31 c15 -17 39 -45 55 -62 48 -57 57 -46 57 73 0 97 -1 105 -20 105 -16 0 -19 -8 -22 -62 l-3 -63 -49 63 c-36 45 -55 62 -73 62 -24 0 -24 0 -21 -102z" />
				<path d="M2692 112 c-23 -54 -42 -101 -42 -105 0 -19 41 -4 50 18 8 22 15 25 58 25 42 0 50 -3 60 -25 7 -15 20 -25 32 -25 26 0 24 8 -31 127 -32 69 -42 83 -61 83 -20 0 -30 -15 -66 -98z m84 13 c19 -45 19 -45 -16 -45 -16 0 -30 3 -30 7 0 10 24 73 28 73 1 0 10 -16 18 -35z" />
				<path d="M3160 105 c0 -98 1 -105 20 -105 18 0 20 7 20 60 0 33 3 60 8 59 4 0 29 -27 55 -60 69 -86 77 -81 77 46 0 97 -1 105 -20 105 -16 0 -19 -8 -22 -62 l-3 -62 -50 62 c-27 34 -58 62 -67 62 -16 0 -18 -12 -18 -105z" />
				<path d="M3682 185 c-38 -32 -48 -72 -28 -118 25 -61 96 -83 158 -51 26 14 29 18 16 30 -11 11 -20 12 -45 2 -64 -24 -114 33 -82 91 16 29 70 41 93 22 10 -8 17 -7 27 5 11 14 10 18 -6 30 -32 23 -99 18 -133 -11z" />
				<path d="M4152 108 l3 -103 78 -3 c72 -3 77 -1 77 18 0 18 -6 20 -55 20 -52 0 -55 1 -55 25 0 23 4 25 45 25 38 0 45 3 45 20 0 17 -7 20 -45 20 -38 0 -45 3 -45 20 0 18 7 20 55 20 48 0 55 2 55 20 0 18 -7 20 -80 20 l-81 0 3 -102z" />
			</g>
		</svg>
	);
};

export default LoganLogo;
