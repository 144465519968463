import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// mui components
import { IconButton, InputLabel, Stack } from "@mui/material";

// custom components
import ViewLoanInfoCard from "../../../pages/view-loan/components/info-card";
import SecondaryButton from "../../buttons/secondary-button";
import MaskedInput from "../../custom-input/masked-input";
import PrimaryButton from "../../buttons/primary-button";
import CustomDivider from "../../custom-divider";
import Heading4 from "../../typography/heading-04";
import Body2 from "../../typography/body-02";

// mui icons
import {
	ArrowBackOutlined,
	ArrowForwardOutlined,
	HelpOutlineOutlined,
} from "@mui/icons-material";

// api slice
import { useFieldMutation } from "../../../features/field-values/fieldValueApiSlice";

// custom modal
import LoaderModal from "../../modal/loader-modal";
import HelpTextModal from "../../modal/help-text-modal";
import ErrorModal from "../../modal/error-modal";

// custom styles
import styles from "./index.module.scss";

const UnderwritingSummary = ({
	setLoanApplicationStage,
	loanGuid,
	viewLoan,
	currentStage,
}) => {
	const params = useParams();
	const loanNumber = params?.loanId;

	const [field, { isLoading: fetchingValues }] = useFieldMutation();

	const [borrowerBaseIncome, setBorrowerBaseIncome] = useState("");
	const [borrowerOtherIncome, setBorrowerOtherIncome] = useState("");
	const [positiveCashFlow, setPositiveCashFlow] = useState("");
	const [totalIncome, setTotalIncome] = useState("");
	const [primaryHousingExpense, setPrimaryHousingExpense] = useState("");
	const [totalObligations, setTotalObligations] = useState("");
	const [presentHousingExpense, setPresentHousingExpense] = useState("");
	const [firstMortgage, setFirstMortgage] = useState("");
	const [subordinateLiens, setSubordinateLiens] = useState("");
	const [homeownersInsurance, setHomeownersInsurance] = useState("");
	const [supplementalInsurance, setSupplementalInsurance] = useState("");
	const [propertyTaxes, setPropertyTaxes] = useState("");
	const [mortgageInsurance, setMortgageInsurance] = useState("");
	const [projectDues, setProjectDues] = useState("");
	const [totalPrimaryHousing, setTotalPrimaryHousing] = useState("");
	const [negativeCashFlow, setNegativeCashFlow] = useState("");
	const [otherMonthlyPayments, setOtherMonthlyPayments] = useState("");
	const [totalMonthlyPayments, setTotalMonthlyPayments] = useState("");

	const [borrowerFirstName, setBorrowerFirstName] = useState("");
	const [borrowerMiddleName, setBorrowerMiddleName] = useState("");
	const [borrowerLastName, setBorrowerLastName] = useState("");

	const [coBorrowerFirstName, setCoBorrowerFirstName] = useState("");
	const [coBorrowerMiddleName, setCoBorrowerMiddleName] = useState("");
	const [coBorrowerLastName, setCoBorrowerLastName] = useState("");

	const [street, setStreet] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [postalCode, setPostalCode] = useState("");

	const [helpText, setHelpText] = useState("");
	const [helpTextOpen, setHelpTextOpen] = useState(false);

	const handleOpenHelpText = () => {
		setHelpTextOpen(true);
	};

	const handleCloseHelpText = () => {
		setHelpTextOpen(false);
	};

	const [errorText, setErrorText] = useState(
		"The DTI on this loan is greater than 100%. Loan Products and Pricing cannot be returned until the DSCR is less than 100%. Please update the income and liabilities fields that impact the DTI so that the DTI is less than 100%. Once the DTI is less than 100% Product and Pricing can be requested."
	);
	const [errorModalVisible, setErrorModalVisible] = useState(false);

	const handleOpenErrorModal = () => {
		setErrorModalVisible(true);
	};

	const handleCloseErrorModal = () => {
		setErrorModalVisible(false);
	};

	const handlePrev = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});

		setLoanApplicationStage(currentStage - 1);
	};

	const findFieldValue = (fieldValues, fieldId) => {
		return fieldValues?.find((o) => o.fieldId === fieldId)?.value;
	};

	const updateField = (fieldValues) => {
		console.log("88 field values:", fieldValues);

		setBorrowerBaseIncome(findFieldValue(fieldValues, "273"));
		setBorrowerOtherIncome(findFieldValue(fieldValues, "1168"));
		setPositiveCashFlow(findFieldValue(fieldValues, "1171"));
		setTotalIncome(findFieldValue(fieldValues, "1389"));
		setPrimaryHousingExpense(findFieldValue(fieldValues, "740"));
		setTotalObligations(findFieldValue(fieldValues, "742"));
		setPresentHousingExpense(findFieldValue(fieldValues, "737"));
		setFirstMortgage(findFieldValue(fieldValues, "228"));
		setSubordinateLiens(findFieldValue(fieldValues, "229"));
		setHomeownersInsurance(findFieldValue(fieldValues, "230"));
		setSupplementalInsurance(findFieldValue(fieldValues, "URLA.X144"));
		setPropertyTaxes(findFieldValue(fieldValues, "1405"));
		setMortgageInsurance(findFieldValue(fieldValues, "232"));
		setProjectDues(findFieldValue(fieldValues, "233"));
		setTotalPrimaryHousing(findFieldValue(fieldValues, "912"));
		setNegativeCashFlow(findFieldValue(fieldValues, "462"));
		setOtherMonthlyPayments(findFieldValue(fieldValues, "1733"));
		setTotalMonthlyPayments(findFieldValue(fieldValues, "1742"));

		setBorrowerFirstName(findFieldValue(fieldValues, "4000"));
		setBorrowerMiddleName(findFieldValue(fieldValues, "4001"));
		setBorrowerLastName(findFieldValue(fieldValues, "4002"));

		setCoBorrowerFirstName(findFieldValue(fieldValues, "4004"));
		setCoBorrowerMiddleName(findFieldValue(fieldValues, "4005"));
		setCoBorrowerLastName(findFieldValue(fieldValues, "4006"));

		setStreet(findFieldValue(fieldValues, "URLA.X73"));
		setCity(findFieldValue(fieldValues, "12"));
		setState(findFieldValue(fieldValues, "14"));
		setPostalCode(findFieldValue(fieldValues, "15"));
	};

	const getFieldValues = async (loanId) => {
		console.log("175 loan id:", loanId);
		try {
			const getValues = await field({
				loan_guid: loanId,
				fields:
					"273,1168,1171,1389,740,742,737,228,229,230,URLA.X144,1405,232,233,912,462,1733,1742,4000,4001,4002,4004,4005,4006,URLA.X73,12,14,15",
			}).unwrap();
			console.log("175 all fields:", getValues?.response);
			if (getValues?.response?.length > 0) {
				updateField(getValues?.response);
			}
		} catch (err) {
			console.log("584 error:", err);
		}
	};

	useEffect(() => {
		console.log("160 loan Guid:", loanGuid);

		if (loanGuid !== undefined && loanGuid !== "") {
			getFieldValues(loanGuid);
		}
	}, [loanGuid]);

	const handleNext = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});

		setLoanApplicationStage(currentStage + 1);
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				{viewLoan && (
					<ViewLoanInfoCard
						loanNumber={loanNumber}
						borrower={{
							firstName: borrowerFirstName,
							middleName: borrowerMiddleName,
							lastName: borrowerLastName,
						}}
						coBorrower={{
							firstName: coBorrowerFirstName,
							middleName: coBorrowerMiddleName,
							lastName: coBorrowerLastName,
						}}
						propertyInfo={{
							addressLineText: street,
							city,
							state,
							postalCode,
						}}
					/>
				)}

				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading4
						text="Debt to Income Ratios (DTI)"
						fontType="semibold"
						underlined={true}
					/>
				</Stack>

				<Stack
					direction="column"
					className={styles.formContainer + " " + styles.paddedContainer}
				>
					{/* Primary Housing Expense/Income */}
					<Stack
						direction="row"
						className={
							styles.inputContainer + " " + styles.incomeInputContainer
						}
					>
						<InputLabel
							htmlFor="primary-housing-expense"
							className={styles.centeredLabel}
						>
							<Body2
								text="Primary Housing Expense/Income:"
								extraClass={styles.sectionHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="primary-housing-expense"
							placeholder="%"
							type="percentage"
							value={primaryHousingExpense?.toString() || ""}
							onInputChange={setPrimaryHousingExpense}
							disabled={true}
						/>
					</Stack>

					{/* Total Obligations/Income */}
					<Stack
						direction="row"
						className={
							styles.inputContainer + " " + styles.incomeInputContainer
						}
					>
						<InputLabel
							htmlFor="total-obligations"
							className={styles.centeredLabel}
						>
							<Body2
								text="Total Obligations/Income:"
								extraClass={styles.sectionHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="total-obligations"
							placeholder="%"
							type="percentage"
							value={totalObligations?.toString() || ""}
							onInputChange={setTotalObligations}
							disabled={true}
						/>
					</Stack>
				</Stack>

				<CustomDivider />

				<Stack direction="row" className={styles.formContainer}>
					<Stack direction="column" flexGrow={1}>
						<Stack direction="column" className={styles.cardHeaderContainer}>
							<Heading4
								text="Monthly Income (All Borrowers)"
								fontType="semibold"
								underlined={true}
							/>
						</Stack>

						<Stack
							direction="column"
							className={styles.formContainer + " " + styles.paddedContainer}
						>
							{/* base income all borrowers */}
							<Stack
								direction="row"
								className={
									styles.inputContainer + " " + styles.incomeInputContainer
								}
							>
								<InputLabel
									htmlFor="borrower-base-income"
									className={styles.centeredLabel}
								>
									<Body2
										text="Base Income All Borrowers:"
										extraClass={styles.sectionHeader}
									/>
								</InputLabel>

								<MaskedInput
									id="borrower-base-income"
									placeholder="$"
									type="currency"
									value={borrowerBaseIncome?.toString() || ""}
									onInputChange={setBorrowerBaseIncome}
									disabled={true}
								/>
							</Stack>

							{/* other income all borrowers */}
							<Stack
								direction="row"
								className={
									styles.inputContainer + " " + styles.incomeInputContainer
								}
							>
								<InputLabel
									htmlFor="borrower-other-income"
									className={styles.centeredLabel}
								>
									<Body2
										text="Other Income All Borrowers:"
										extraClass={styles.sectionHeader}
									/>
								</InputLabel>

								<MaskedInput
									id="borrower-other-income"
									placeholder="$"
									type="currency"
									value={borrowerOtherIncome?.toString() || ""}
									onInputChange={setBorrowerOtherIncome}
									disabled={true}
								/>
							</Stack>

							{/* Positive Cash Flow (Subject Property) */}
							<Stack
								direction="row"
								className={
									styles.inputContainer + " " + styles.incomeInputContainer
								}
							>
								<InputLabel
									htmlFor="positive-cash-flow"
									className={styles.centeredLabel}
								>
									<Body2
										text="Positive Cash Flow (Subject Property):"
										extraClass={styles.sectionHeader}
									/>
								</InputLabel>

								<MaskedInput
									id="positive-cash-flow"
									placeholder="$"
									type="currency"
									value={positiveCashFlow?.toString() || ""}
									onInputChange={setPositiveCashFlow}
									disabled={true}
								/>
							</Stack>

							{/* total Income */}
							<Stack
								direction="row"
								className={
									styles.inputContainer + " " + styles.incomeInputContainer
								}
							>
								<InputLabel
									htmlFor="total-income"
									className={styles.centeredLabel}
								>
									<Body2
										text="Total Income:"
										extraClass={styles.sectionHeader}
										fontType="semibold"
									/>
								</InputLabel>

								<MaskedInput
									id="total-income"
									placeholder="$"
									type="currency"
									value={totalIncome?.toString() || ""}
									onInputChange={setTotalIncome}
									disabled={true}
								/>
							</Stack>
						</Stack>

						<CustomDivider />

						<Stack direction="column" className={styles.cardHeaderContainer}>
							<Heading4
								text="Other Obligations (All Borrowers)"
								fontType="semibold"
								underlined={true}
							/>
						</Stack>

						<Stack
							direction="column"
							className={styles.formContainer + " " + styles.paddedContainer}
						>
							{/* Negative Cash Flow (Subject Property) */}
							<Stack
								direction="row"
								className={
									styles.inputContainer + " " + styles.incomeInputContainer
								}
							>
								<InputLabel
									htmlFor="negative-cash-flow"
									className={styles.centeredLabel}
								>
									<Body2
										text="Negative Cash Flow (Subject Property):"
										extraClass={styles.sectionHeader}
									/>
								</InputLabel>

								<MaskedInput
									id="negative-cash-flow"
									placeholder="$"
									type="currency"
									value={negativeCashFlow?.toString() || ""}
									onInputChange={setNegativeCashFlow}
									disabled={true}
								/>
							</Stack>

							{/* All Other Monthly Payments */}
							<Stack
								direction="row"
								className={
									styles.inputContainer + " " + styles.incomeInputContainer
								}
							>
								<InputLabel
									htmlFor="other-monthly-payments"
									className={styles.centeredLabel}
								>
									<Body2
										text="All Other Monthly Payments:"
										extraClass={styles.sectionHeader}
									/>
								</InputLabel>

								<MaskedInput
									id="other-monthly-payments"
									placeholder="$"
									type="currency"
									value={otherMonthlyPayments?.toString() || ""}
									onInputChange={setOtherMonthlyPayments}
									disabled={true}
								/>
							</Stack>

							{/* Total All Monthly Payments */}
							<Stack
								direction="row"
								className={
									styles.inputContainer + " " + styles.incomeInputContainer
								}
							>
								<InputLabel
									htmlFor="total-monthly-payments"
									className={styles.centeredLabel}
								>
									<Body2
										text="Total All Monthly Payments:"
										extraClass={styles.sectionHeader}
										fontType="semibold"
									/>
								</InputLabel>

								<MaskedInput
									id="total-monthly-payments"
									placeholder="$"
									type="currency"
									value={totalMonthlyPayments?.toString() || ""}
									onInputChange={setTotalMonthlyPayments}
									disabled={true}
								/>
							</Stack>
						</Stack>

						<CustomDivider />
					</Stack>

					<Stack direction="column" flexGrow={1}>
						<Stack direction="column" className={styles.cardHeaderContainer}>
							<Heading4
								text="Housing Expenses"
								fontType="semibold"
								underlined={true}
							/>
						</Stack>

						<Stack
							direction="column"
							className={
								styles.bottomFormContainer + " " + styles.paddedContainer
							}
						>
							{/* Present Housing Expense */}
							<Stack
								direction="row"
								className={
									styles.inputContainer +
									" " +
									styles.incomeInputContainer +
									" " +
									styles.presentHousing
								}
							>
								<Stack direction="row" alignItems="center">
									<InputLabel
										htmlFor="present-housing-expense"
										className={styles.centeredLabel}
									>
										<Body2
											text="Present Housing Expense:"
											extraClass={styles.sectionHeader}
											fontType="semibold"
										/>
									</InputLabel>

									<IconButton
										className={styles.helperIconBtn}
										onClick={() => {
											setHelpText(
												"This is the borrowers current housing expense that will be included in their DTI Ratios when the occupancy for the submitted loan is “Investment” or “Second Home”. This data is entered on the “Borrower Information” Screen. When the loan is for an owner-occupied property the value of this field will be null."
											);
											handleOpenHelpText();
										}}
									>
										<HelpOutlineOutlined className={styles.helperIcon} />
									</IconButton>
								</Stack>

								<MaskedInput
									id="present-housing-expense"
									placeholder="$"
									type="currency"
									value={presentHousingExpense?.toString() || ""}
									onInputChange={setPresentHousingExpense}
									disabled={true}
								/>
							</Stack>

							{/* Proposed Housing Expense */}
							<Stack direction="row" className={styles.inputContainer}>
								<Stack direction="row" alignItems="center">
									<Body2
										text="Proposed Housing Expense"
										extraClass={styles.sectionHeader}
										fontType="semibold"
									/>

									<IconButton
										className={styles.helperIconBtn}
										onClick={() => {
											setHelpText(
												"This is the proposed housing expense of the subject property. The data for these fields are entered on the “Borrower Information” screen."
											);
											handleOpenHelpText();
										}}
									>
										<HelpOutlineOutlined className={styles.helperIcon} />
									</IconButton>
								</Stack>
							</Stack>

							<Stack
								direction="column"
								className={styles.formContainer + " " + styles.paddedContainer}
							>
								{/* First Mortgage (P&I) */}
								<Stack
									direction="row"
									className={
										styles.inputContainer + " " + styles.incomeInputContainer
									}
								>
									<InputLabel
										htmlFor="first-mortgage"
										className={styles.centeredLabel}
									>
										<Body2
											text="First Mortgage (P&I):"
											extraClass={styles.sectionHeader}
										/>
									</InputLabel>

									<MaskedInput
										id="first-mortgage"
										placeholder="$"
										type="currency"
										value={firstMortgage?.toString() || ""}
										onInputChange={setFirstMortgage}
										disabled={true}
									/>
								</Stack>

								{/* Subordinate Liens (P&I) */}
								<Stack
									direction="row"
									className={
										styles.inputContainer + " " + styles.incomeInputContainer
									}
								>
									<InputLabel
										htmlFor="subordinate-liens"
										className={styles.centeredLabel}
									>
										<Body2
											text="Subordinate Liens (P&I):"
											extraClass={styles.sectionHeader}
										/>
									</InputLabel>

									<MaskedInput
										id="subordinate-liens"
										placeholder="$"
										type="currency"
										value={subordinateLiens?.toString() || ""}
										onInputChange={setSubordinateLiens}
										disabled={true}
									/>
								</Stack>

								{/* Homeowner's Insurance */}
								<Stack
									direction="row"
									className={
										styles.inputContainer + " " + styles.incomeInputContainer
									}
								>
									<InputLabel
										htmlFor="homeowners-insurance"
										className={styles.centeredLabel}
									>
										<Body2
											text="Homeowner's Insurance:"
											extraClass={styles.sectionHeader}
										/>
									</InputLabel>

									<MaskedInput
										id="homeowners-insurance"
										placeholder="$"
										type="currency"
										value={homeownersInsurance?.toString() || ""}
										onInputChange={setHomeownersInsurance}
										disabled={true}
									/>
								</Stack>

								{/* Supplemental Property Insurance */}
								<Stack
									direction="row"
									className={
										styles.inputContainer + " " + styles.incomeInputContainer
									}
								>
									<InputLabel
										htmlFor="supplemental-insurance"
										className={styles.centeredLabel}
									>
										<Body2
											text="Supplemental Property Insurance:"
											extraClass={styles.sectionHeader}
										/>
									</InputLabel>

									<MaskedInput
										id="supplemental-insurance"
										placeholder="$"
										type="currency"
										value={supplementalInsurance?.toString() || ""}
										onInputChange={setSupplementalInsurance}
										disabled={true}
									/>
								</Stack>

								{/* Property Taxes */}
								<Stack
									direction="row"
									className={
										styles.inputContainer + " " + styles.incomeInputContainer
									}
								>
									<InputLabel
										htmlFor="property-taxes"
										className={styles.centeredLabel}
									>
										<Body2
											text="Property Taxes:"
											extraClass={styles.sectionHeader}
										/>
									</InputLabel>

									<MaskedInput
										id="property-taxes"
										placeholder="$"
										type="currency"
										value={propertyTaxes?.toString() || ""}
										onInputChange={setPropertyTaxes}
										disabled={true}
									/>
								</Stack>

								{/* Mortgage Insurance */}
								<Stack
									direction="row"
									className={
										styles.inputContainer + " " + styles.incomeInputContainer
									}
								>
									<InputLabel
										htmlFor="mortgage-insurance"
										className={styles.centeredLabel}
									>
										<Body2
											text="Mortgage Insurance:"
											extraClass={styles.sectionHeader}
										/>
									</InputLabel>

									<MaskedInput
										id="mortgage-insurance"
										placeholder="$"
										type="currency"
										value={mortgageInsurance?.toString() || ""}
										onInputChange={setMortgageInsurance}
										disabled={true}
									/>
								</Stack>

								{/* HOA/Project Dues */}
								<Stack
									direction="row"
									className={
										styles.inputContainer + " " + styles.incomeInputContainer
									}
								>
									<InputLabel
										htmlFor="project-dues"
										className={styles.centeredLabel}
									>
										<Body2
											text="HOA/Project Dues:"
											extraClass={styles.sectionHeader}
										/>
									</InputLabel>

									<MaskedInput
										id="project-dues"
										placeholder="$"
										type="currency"
										value={projectDues?.toString() || ""}
										onInputChange={setProjectDues}
										disabled={true}
									/>
								</Stack>

								{/* Total Proposed Housing Expense */}
								<Stack
									direction="row"
									className={
										styles.inputContainer + " " + styles.incomeInputContainer
									}
								>
									<InputLabel
										htmlFor="total-primary-housing"
										className={styles.centeredLabel}
									>
										<Body2
											text="Total Proposed Housing Expense:"
											extraClass={styles.sectionHeader}
											fontType="semibold"
										/>
									</InputLabel>

									<MaskedInput
										id="total-primary-housing"
										placeholder="$"
										type="currency"
										value={totalPrimaryHousing?.toString() || ""}
										onInputChange={setTotalPrimaryHousing}
										disabled={true}
									/>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
			</Stack>

			{/* footer buttons */}
			<Stack direction="row" className={styles.footerContainer}>
				<SecondaryButton
					text="Previous"
					startIcon={<ArrowBackOutlined className={styles.prevIcon} />}
					onClick={handlePrev}
				/>
				<PrimaryButton
					text={viewLoan ? "Continue" : "Save and Continue"}
					endIcon={<ArrowForwardOutlined className={styles.nextIcon} />}
					onClick={() =>
						Number(totalObligations > 100)
							? handleOpenErrorModal()
							: handleNext()
					}
				/>
			</Stack>

			{/* modal */}
			<LoaderModal open={fetchingValues} />

			<HelpTextModal
				open={helpTextOpen}
				modalText={helpText}
				handleClose={handleCloseHelpText}
			/>
			<ErrorModal
				open={errorModalVisible}
				text={errorText}
				handleClose={handleCloseErrorModal}
			/>
		</Stack>
	);
};

export default UnderwritingSummary;
